<template>
  <!-- <VDialog
    v-model="visibility"
    max-width="1068px"
    :persistent="changed"
    @click:outside="showConfirmDialog"
    content-class="cross__dialog"
    scrollable
  > -->
  <div>
    <v-card
      :loading="isLoadingCard"
      :disabled="isLoadingCard"
      :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-5'"
      :height="getTableHeight"
      elevation="0"
    >
      <div class="cross__inner" v-if="!loadingChosenProducts && !loading && !noCross">
        <v-btn fab small elevation="0" @click="showConfirmDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="pt-5 pb-0">
        <v-row>
          <headerDialogCrm
            v-if="settingDialog === 'crm'"
            ref="header_crm"
            :uuid_state="uuid_user_state"
            :list_states="user_states"
            @change="setUserState"
            :item="item"
            :typeDialog="typeDialog"
            :loading="loading"
            :activeProduct="activeProduct"
            @callRequiredUpdate="callRequiredUpdate"
            @assignUpdate="assignUpdate"
            :inputDelivery="inputDelivery"
          />
          <headerDialogSupply
            v-else-if="settingDialog === 'supply'"
            :uuid_state="Number(inputCrmItem.id_state)"
            :list_states="filteredStates"
            :typeDialog="typeDialog"
            @change="setOrderState"
            :itemNumber="item.number"
            :itemUuid="item.uuid"
            :loading="loading"
            :is_blocked="inputCrmItem.is_blocked"
            @blocked="requestBlocked"
            @onDeleteSupply="onDeleteSupply"
            @showConfirmDialog="showConfirmDialog"
            @onReturn="visibleReturnDialog = true"
            @onCancelReturn="cancelReturn = !cancelReturn"
            :activeProduct="activeProduct"
            :tabStep="tabStep"
            :cancelReturn="cancelReturn"
            :item="item"
          />
          <v-col cols="12" class="pt-0">
            <div class="orderDialog__nav ">
              <div class="d-flex align-center">
                <v-btn
                  class="btn-tabNav"
                  @click="tabStep = 0"
                  :class="{ active: tabStep === 0, 'mr-0': $vuetify.breakpoint.smAndDown }"
                >
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.supply_settings") }}
                  </div>
                  <v-icon v-else small>mdi-cog-transfer-outline</v-icon>
                  <VFadeTransition>
                    <v-icon small color="error" class="ml-1" v-if="this.$v.$error"
                      >mdi-alert-circle</v-icon
                    >
                  </VFadeTransition>
                </v-btn>

                <v-tooltip v-if="!activeProduct" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center" v-on="on" :class="!$vuetify.breakpoint.smAndDown ? 'btn-tabNav' : ''">
                      <v-btn
                        v-bind="attrs"
                        class="btn-tabNav"
                        disabled
                      >
                            <v-icon small :left="!$vuetify.breakpoint.smAndDown"
                              >mdi-package-variant-closed</v-icon
                            >
                            <div v-if="!$vuetify.breakpoint.smAndDown">
                              {{ $t("supply.item") }}
                            </div>
                      </v-btn>
                    </div>
                  </template>
                  <span>
                    {{ $t("supply.first_create_order") }}
                  </span>
                </v-tooltip>
                <v-btn
                  v-if="activeProduct"
                  class="btn-tabNav"
                  @click="tabStep = 1"
                  :class="{ active: tabStep === 1, 'mr-0': $vuetify.breakpoint.smAndDown }"
                >
                  <v-icon small :left="!$vuetify.breakpoint.smAndDown"
                    >mdi-package-variant-closed</v-icon
                  >
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.item") }}
                  </div>
                </v-btn>
                <!-- <v-btn
                  class="btn-tabNav"
                  @click="tabStep = 2"
                  :class="{ active: tabStep === 2, 'mr-0': $vuetify.breakpoint.smAndDown }"
                  :disabled="!activeProduct"
                >
                  <v-icon small :left="!$vuetify.breakpoint.smAndDown">mdi-face-agent</v-icon>
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.order_history") }}
                  </div>
                </v-btn> -->
                <v-btn
                  class="btn-tabNav"
                  v-if="settingDialog === 'crm'"
                  @click="tabStep = 3"
                  :class="{ active: tabStep === 3 }"
                  :disabled="!activeProduct"
                >
                  {{ $t("supply.info") }}
                </v-btn>
              </div>
              <!-- <v-row
              :style="Number(inputCrmItem.id_state) >= 401 ? { 'justify-content': 'end' } : { 'justify-content': 'end' }"
              no-gutters> -->
              <v-row style="justify-content: end" no-gutters>
                <v-col
                  cols="auto"
                  class="pa-0"
                  v-if="
                    watchDeliveryItem() &&
                      tabStep === 1 &&
                      productTab === 'edit' &&
                      settingDialog == 'crm'
                  "
                >
                  <v-btn
                    dense
                    plain
                    class="text-transform-none px-2"
                    style="color: green"
                    @click="onClickAddCRMProducts"
                  >
                    <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</v-icon>
                    <div>
                      {{ $t("supply.add_Btn") }}
                    </div>
                  </v-btn>
                </v-col>
                <v-col
                  cols="auto"
                  class="pa-0"
                  v-if="
                    watchDeliveryItem() &&
                      tabStep === 1 &&
                      productTab === 'edit' &&
                      this.inputCrmItem.id_state != 401 &&
                      this.inputCrmItem.id_state != 410 &&
                      this.inputCrmItem.id_state != 411 &&
                      this.inputCrmItem.id_state != 412
                  "
                >
                  <v-btn
                    dense
                    plain
                    class="text-transform-none px-2"
                    style="color: green"
                    @click="onClickAddNewProducts"
                  >
                    <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</v-icon>
                    <div>
                      {{ $t("supply.add_fromDeportBtn") }}
                    </div>
                  </v-btn>
                </v-col>
                <v-slide-x-transition>
                  <v-col
                    cols="auto"
                    class="px-0"
                    v-if="item.id_state >= 430 && tabItemsStep === 1 && tabStep === 1"
                  >
                    <v-btn
                      color="primary"
                      class="br-10 text-transform-none"
                      plain
                      @click="onClickMore"
                    >
                      <v-icon :left="!$vuetify.breakpoint.smAndDown">
                        mdi-printer
                      </v-icon>
                      <div v-if="!$vuetify.breakpoint.smAndDown">
                        {{ $t("btn.print") }}
                      </div>
                    </v-btn>
                  </v-col>
                </v-slide-x-transition>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text
        class="pb-0"
        :class="tabStep === 2 ? 'pr-0' : ''"
        style="height:460px"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'max-height:250px;overflow:scroll'
            : `max-height:${getCardHeight};overflow:scroll`
        "
      >
        <v-tabs-items v-model="tabStep" :touchless="true">
          <v-tab-item>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12" md="6" class="ma-0 pa-0">
                    <v-col
                      cols="12"
                      v-if="getUser !== null && getUser.is_np_apikey === '1' && permissions.client"
                    >
                      <div class="d-flex align-center">
                        <VSelect
                          dense
                          :label="$t('form.counterparty')"
                          outlined
                          :items="listCounterparty"
                          v-model="counterparty"
                          item-text="name"
                          return-object
                          class="br-10"
                          color="success"
                          item-color="success"
                          hide-details=""
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              !statusCreateTTN ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder ||
                              !getUser.np_apikey
                          "
                        />
                        <v-switch
                          class="ml-2"
                          color="success"
                          hide-details
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              ttnCodeStatus ||
                              isSendedCrmOrder ||
                              !getUser.np_apikey ||
                              !listCounterparty.length
                          "
                          @change="
                            switchStatusNP();
                            OnChangeStatusCreateTTN();
                          "
                          v-model="statusCreateTTN"
                        />
                      </div>
                    </v-col>
                    <v-col
                      v-click-outside="onSenderOutsideClick"
                      cols="12"
                      v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown"
                    >
                      <VTextField
                        v-model="inputSender.phone"
                        outlined
                        hide-details="auto"
                        dense
                        prefix="+38"
                        :maxLength="20"
                        :error-messages="senderPhoneErrors"
                        class="br-10 inputPhone"
                        :label="$t('table.phone')"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        @keydown="sender.phoneMenu = true"
                        @input="onInputSenderValue('phone', $event)"
                        color="success"
                        v-on:keypress="validationForPhoneNumber($event)"
                        @paste="validationForPhoneNumber($event)"
                      />

                      <div class="px-2 position-relative" v-if="sender.items && sender.phoneMenu">
                        <v-list
                          dense
                          class="elevation-5 search-dropdown"
                          v-if="sender.items.length"
                        >
                          <v-list-item
                            class="py-0"
                            v-for="(item, index) in sender.items"
                            :key="index"
                            @click="
                              onChangeSenderAddress('phone', item);
                              sender.phoneMenu = false;
                            "
                          >
                            <v-list-item-title class="search-menu-item">
                              {{ item.name || "--" }} {{ item.surname || "--" }}
                              {{ item.middlename || "--" }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown">
                      <VTextField
                        v-model="inputSender.name"
                        hide-details="auto"
                        outlined
                        dense
                        :label="$t('form.sender_name')"
                        class="br-10"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                        @input="noEngText($event, false)"
                        :error-messages="senderNameErrors"
                        color="success"
                        v-on:keypress="validationForName($event)"
                        @paste="validationForName($event)"
                        :maxlength="20"
                      />
                    </v-col>
                    <v-col cols="12" v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown">
                      <VTextField
                        language="ar"
                        v-model="inputSender.surname"
                        hide-details="auto"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.sender_surname')"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                        @input="noEngText($event, false)"
                        :error-messages="senderSurnameErrors"
                        color="success"
                        v-on:keypress="validationForSurname($event)"
                        @paste="validationForSurname($event)"
                        :maxlength="20"
                      />
                    </v-col>
                    <v-col cols="12" v-if="!statusCreateTTN || !$vuetify.breakpoint.smAndDown">
                      <VTextField
                        language="ar"
                        v-model="inputSender.middlename"
                        hide-details="auto"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.sender_middlename')"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            (statusCreateTTN && permissions.client) ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.MiddlenameNoEngText')"
                        @input="noEngText($event, false)"
                        :error-messages="senderMiddlenameErrors"
                        color="success"
                        v-on:keypress="validationForSurname($event)"
                        @paste="validationForSurname($event)"
                        :maxlength="20"
                      />
                    </v-col>
                    <v-col cols="12" class="">
                      <VTextarea
                        height="104"
                        no-resize
                        rows="3"
                        color="success"
                        class="br-10"
                        outlined
                        dense
                        :label="$t('form.comment')"
                        v-model="inputCrmItem.description"
                        hide-details="auto"
                        required
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.CommentNoEngText')"
                        v-on:keypress="validationForDepartment($event)"
                        @paste="validationForDepartment($event)"
                        :maxlength="256"
                      ></VTextarea>
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-0 ma-0 pl-3">
                    <v-col v-click-outside="onDeliveryOutsideClick" cols="12">
                      <VTextField
                        v-model="inputDelivery.phone"
                        outlined
                        dense
                        class="br-10 inputPhone"
                        :label="$t('form.phone_receiver')"
                        return-masked-value
                        prefix="+38"
                        :maxLength="20"
                        :hide-details="!deliveryPhoneErrors.length"
                        :error-messages="deliveryPhoneErrors"
                        :disabled="
                          blockedItem ||
                            item.id_state === '440' ||
                            isSendedCrmOrder ||
                            !watchDeliveryItem() ||
                            ttnCodeStatus
                        "
                        @keydown="delivery.phoneMenu = true"
                        @input="onInputDeliveryValue('phone', $event)"
                        color="success"
                        v-on:keypress="validationForPhoneNumber($event)"
                        @paste="validationForPhoneNumber($event)"
                      >
                      </VTextField>
                      <div
                        class="px-2 position-relative"
                        v-if="delivery.items.length && delivery.phoneMenu"
                      >
                        <v-list dense class="elevation-5 search-dropdown">
                          <v-list-item
                            class="py-0"
                            v-for="(item, index) in delivery.items"
                            :key="index"
                            @click="
                              onChangeDeliveryAddress('phone', item);
                              delivery.phoneMenu = false;
                            "
                          >
                            <v-list-item-title class="search-menu-item">
                              {{ item.name }} {{ item.surname }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <VTextField
                        v-model="inputDelivery.name"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.name_receiver')"
                        hide-details="auto"
                        :error-messages="deliveryNameErrors"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                        @input="noEngText($event, false)"
                        color="success"
                        v-on:keypress="validationForName($event)"
                        @paste="validationForName($event)"
                        :maxlength="20"
                      />
                    </v-col>
                    <v-col cols="12">
                      <VTextField
                        v-model="inputDelivery.surname"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.surname_receiver')"
                        hide-details="auto"
                        :error-messages="deliverySurnameErrors"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                        @input="noEngText($event, false)"
                        color="success"
                        v-on:keypress="validationForSurname($event)"
                        @paste="validationForSurname($event)"
                        :maxlength="20"
                      />
                    </v-col>
                    <v-col cols="12" class="pb-1">
                      <VTextField
                        v-model="inputDelivery.middlename"
                        outlined
                        dense
                        class="br-10"
                        :label="$t('form.middlename_receiver')"
                        hide-details="auto"
                        :error-messages="deliveryMiddlenameErrors"
                        :disabled="
                          blockedItem ||
                            !watchDeliveryItem() ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        :hint="onEngText ? '' : $t('form.hint.MiddlenameNoEngText')"
                        @input="noEngText($event, false)"
                        color="success"
                        v-on:keypress="validationForSurname($event)"
                        @paste="validationForSurname($event)"
                        :maxlength="20"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0 d-flex justify-center align-center">
                      <v-chip-group
                        :disabled="
                          blockedItem ||
                            ttnCodeStatus ||
                            item.id_state === '440' ||
                            isSendedCrmOrder
                        "
                        active-class="success--text"
                        hide-details
                        row
                        mandatory
                        v-model="radioServiceType"
                        color="success"
                      >
                        <v-chip
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          small
                          value="WarehouseWarehouse"
                          >{{ $t("orderDialog.warehouse") }}
                        </v-chip>
                        <v-chip
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          value="DoorsWarehouse"
                          small
                          >{{ $t("orderDialog.address") }}</v-chip
                        >
                      </v-chip-group>
                      <v-divider />
                    </v-col>
                    <div v-if="radioServiceType == 'WarehouseWarehouse'">
                      <v-col cols="12" class="pt-1">
                        <VAutocomplete
                          :items="activeCities"
                          :loading="regionLoading || isLoadingCity"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('form.city_receiver')"
                          item-text="Description"
                          item-value="Ref"
                          :search-input.sync="searchCityQuery"
                          :filter="filterCities"
                          :hide-details="!deliveryCityErrors.length"
                          :error-messages="deliveryCityErrors"
                          hide-no-data
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          @change="onChangeCity(inputDelivery.city, 'delivery')"
                          v-model="inputDelivery.city"
                          ref="deliveryCity"
                          @focus="onFocusCitiesHandler()"
                          color="success"
                          item-color="success"
                          v-on:keypress="validationForCities($event)"
                          @paste="validationForCities($event)"
                        >
                          <template v-slot:item="{ item }">
                            <v-col class="col-12 pa-0 ma-0 mt-2">
                              <v-row no-gutters class="text-body-2">
                                {{ item.Description }}
                              </v-row>
                              <v-row no-gutters class="text-caption">
                                {{ item.RegionsDescription }}
                                <span v-if="item.RegionsDescription">&nbsp;-&nbsp;</span>
                                {{ item.AreaDescription }}
                              </v-row>
                            </v-col>
                          </template>
                          <template v-slot:append-item>
                            <div
                              v-if="!cityPagination.isLoadDisabled"
                              v-observer="{ nextPage: nextPageCities }"
                            />
                          </template>
                        </VAutocomplete>
                      </v-col>
                      <v-col>
                        <VAutocomplete
                          :items="activeWarehouse"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('form.department')"
                          no-data-text="--"
                          item-text="Description"
                          item-value="Ref"
                          return-object
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              ttnCodeStatus ||
                              !inputDelivery.city ||
                              item.id_state === '440' ||
                              !searchCityQuery
                          "
                          @change="onSelectWarehouse('delivery')"
                          v-model="inputDelivery.warehouse"
                          :search-input.sync="searchWarehouseQuery"
                          :hide-details="!deliveryWarehouseErrors.length"
                          :error-messages="deliveryWarehouseErrors"
                          color="success"
                          item-color="success"
                          v-on:keypress="validationForDepartment($event)"
                          @paste="validationForDepartment($event)"
                        >
                          <template v-slot:append-item>
                            <div
                              v-if="!warehousePagination.isLoadDisabled"
                              v-observer="{ nextPage: nextPageWarehouse }"
                            ></div>
                          </template>
                        </VAutocomplete>
                      </v-col>
                    </div>
                    <div v-if="radioServiceType == 'DoorsWarehouse' && !movement.check">
                      <v-col cols="12">
                        <VAutocomplete
                          :items="settlementsList"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('form.city_receiver')"
                          return-object
                          :item-text="'Description'"
                          :item-value="'Ref'"
                          hide-no-data
                          @change="getStreets(false, true)"
                          no-filter
                          :search-input.sync="searchAddressCityQuery"
                          :hide-details="!deliveryAddressCityErrors.length"
                          :error-messages="deliveryAddressCityErrors"
                          :loading="cityAddressLoading"
                          v-model="inputAddress.city_address"
                          loader-height="2"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          color="success"
                          item-color="success"
                          v-on:keypress="validationForCities($event)"
                          @paste="validationForCities($event)"
                        >
                          <template v-slot:item="{ item }">
                            <v-col class="col-12 pa-0 ma-0 mt-2">
                              <v-row no-gutters class="text-body-2">
                                {{ item.Description }}
                              </v-row>
                              <v-row no-gutters class="text-caption">
                                {{ item.RegionsDescription }}
                                <span v-if="item.RegionsDescription">&nbsp;-&nbsp;</span>
                                {{ item.AreaDescription }}
                              </v-row>
                            </v-col>
                          </template>
                          <template v-slot:append-item>
                            <div
                              v-if="!loadMoreCitiesDisabled"
                              v-observer="{ nextPage: nextPageSettlements }"
                            />
                          </template>
                        </VAutocomplete>
                      </v-col>
                      <v-col cols="12">
                        <VAutocomplete
                          :items="streetsList"
                          outlined
                          dense
                          class="br-10"
                          :label="$t('orderDialog.street')"
                          return-object
                          :item-text="'Description'"
                          :item-value="'Ref'"
                          :no-filter="true"
                          :hide-details="!deliveryAddressStreetErrors.length"
                          :error-messages="deliveryAddressStreetErrors"
                          v-model="inputAddress.city_street"
                          :search-input.sync="searchAddressStreetQuery"
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder ||
                              !Object.values(inputAddress.city_address).length
                          "
                          color="success"
                          item-color="success"
                          v-on:keypress="validationForStreets($event)"
                          @paste="validationForStreets($event)"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-body-2">
                              {{ item.StreetsType }} {{ item.Description }}
                            </div>
                          </template>

                          <template v-slot:append-item>
                            <div
                              v-if="!loadMoreStreetsDisabled"
                              v-observer="{ nextPage: nextPageStreets }"
                            />
                          </template>
                        </VAutocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="6">
                            <VTextField
                              :label="$t('orderDialog.house')"
                              :disabled="
                                blockedItem ||
                                  ttnCodeStatus ||
                                  item.id_state === '440' ||
                                  isSendedCrmOrder ||
                                  !Object.values(inputAddress.city_street).length
                              "
                              outlined
                              v-model="inputAddress.city_house"
                              dense
                              :hide-details="!deliveryAddressHouseErrors.length"
                              :error-messages="deliveryAddressHouseErrors"
                              class="br-10"
                              color="success"
                              v-on:keypress="validationForHouses($event)"
                              @paste="validationForHouses($event)"
                            >
                            </VTextField>
                          </v-col>
                          <v-col cols="6">
                            <VTextField
                              :disabled="
                                blockedItem ||
                                  ttnCodeStatus ||
                                  item.id_state === '440' ||
                                  isSendedCrmOrder ||
                                  !inputAddress?.city_house
                              "
                              :label="$t('orderDialog.flat')"
                              v-model="inputAddress.city_flat"
                              outlined
                              dense
                              hide-details="auto"
                              class="br-10"
                              color="success"
                              v-on:keypress="validationForHouses($event)"
                              @paste="validationForHouses($event)"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <div v-if="radioServiceType == 'DoorsWarehouse' && movement.check">
                      <v-col class="py-0">
                        <VTextarea
                          label="Адреса"
                          height="104"
                          no-resize
                          rows="3"
                          disabled
                          color="success"
                          class="br-10"
                          outlined
                          v-model="movement.address"
                        ></VTextarea>
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row justify="end" class="py-0 pb-1 px-3" no-gutters>
                  <v-col cols="auto" align-self="end" class="pa-0 pl-1" v-if="settingDialog == 'crm'">
                    <v-menu
                      v-if="item.uuid_receipt != '00000000-0000-0000-0000-000000000000'"
                      bottom
                      origin="center center"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :offset-x="true"
                      z-index="202"
                      v-model="cashierDialogMenu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="success-bg text-white text-transform-none btn-edit"
                          text
                          v-on="on"
                          v-bind="attrs"
                          :disabled="!(item.id_state == '450' || item.id_state == '451') && !isEnoughProducts"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-on="on"
                                v-bind="attrs"
                                class="pointer-events-auto"
                                color="white"
                              >
                              mdi-receipt-text
                              </v-icon>
                            </template>
                            <span>
                        {{
                          (item.id_state !== "450" && item.id_state !== "451")
                            ? $t("orderDialog.checkbox_integration_status_must_be")
                            : (isEnoughProducts ? $t("orderDialog.checkbox_integration") : $t("orderDialog.checkbox_chosen_products"))
                        }}
                      </span>
                          </v-tooltip>
                        </v-btn>
                      </template>
                      <cashierDialog
                        :item="item"
                        @close="cashierDialogMenu = false"
                        v-if="cashierDialogMenu"
                      />
                    </v-menu>
                    <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="success-bg text-white text-transform-none btn-edit"
                            text
                            v-on="on"
                            v-bind="attrs"
                            :disabled="!(item.id_state == '450' || item.id_state == '451') || !isEnoughProducts"
                            @click="chooseCashierDialog = true"
                            ><v-icon
                              class="pointer-events-auto"
                              color="white">mdi-receipt-text</v-icon></v-btn
                            >
                        </template>
                         <span>
                        {{
                          (item.id_state !== "450" && item.id_state !== "451")
                            ? $t("orderDialog.checkbox_integration_status_must_be")
                            : (isEnoughProducts ? $t("orderDialog.checkbox_integration") : $t("orderDialog.checkbox_chosen_products"))
                        }}
                      </span>
                      </v-tooltip>
                      <chooseCashierDialog
                        :item="item"
                        :visible="chooseCashierDialog"
                        @close="chooseCashierDialogClose"
                        v-if="chooseCashierDialog"
                        :chosenProducts="chosenProducts"
                      />
                  </v-col>
                  <v-col cols="auto" align-self="end" class="pa-0 pl-1">
                    <v-menu
                      bottom
                      origin="center center"
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :offset-x="true"
                      z-index="202"
                      v-model="smsIntegrationMenu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="success-bg text-white text-transform-none btn-edit"
                          text
                          v-on="on"
                          v-bind="attrs"
                          :disabled="!inputDelivery.phone"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-on="on"
                                v-bind="attrs"
                                class="pointer-events-auto"
                                color="white"
                              >
                                mdi-phone-message-outline
                              </v-icon>
                            </template>
                            {{ $t("orderDialog.sms_sending") }}
                          </v-tooltip>
                        </v-btn>
                      </template>
                      <ns-sms-integration
                        :item="inputDelivery"
                        :ttn="inputCrmItem.ttn"
                        v-if="smsIntegrationMenu"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="auto" class="pa-0 pl-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="success-bg text-white text-transform-none  btn-edit"
                          v-on="on"
                          v-bind="attrs"
                          outlined
                          @click="generationTTNconfirm = true"
                          :disabled="
                            changed ||
                              blockedItem ||
                              !watchDeliveryItem() ||
                              !statusCreateTTN ||
                              loadingCreateTTN ||
                              ttnCodeStatus ||
                              loading ||
                              !activeProduct ||
                              !permissions.can_generate_ttn
                          "
                        >
                          <v-icon class="pointer-events-auto">
                            mdi-cube-send
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t("generate_ttn.generate_ttn") }}
                    </v-tooltip>
                  </v-col>
                  <v-col cols="auto" class="pa-0 pl-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="success-bg text-white text-transform-none  btn-edit"
                          v-on="on"
                          v-bind="attrs"
                          outlined
                          @click="pushToNPInfo()"
                          :disabled="
                            !statusCreateTTN || loadingCreateTTN || !ttnCodeStatus || loading
                          "
                        >
                          <v-icon class="pointer-events-auto">
                            mdi-cube-scan
                          </v-icon>
                        </v-btn>
                      </template>
                      {{  $t("orderDialog.show_info_about_ttn") }}
                    </v-tooltip>
                  </v-col>

                  <v-col
                    v-if="settingDialog === 'crm' && activeProduct"
                    cols="auto"
                    align-self="end"
                    class="pa-0 pl-1"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="success-bg text-white text-transform-none btn-edit"
                          @click="onUpdate('save')"
                          :disabled="(!changed && watchDelivery()) || isSendedCrmOrder"
                          text
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon dense class="pointer-events-auto">mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("btn.update") }}
                    </v-tooltip>
                  </v-col>
                  <v-col
                    class="pa-0 pl-1"
                    v-if="settingDialog === 'supply' && watchDelivery() && activeProduct"
                    cols="auto"
                    align-self="end"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="success-bg text-white text-transform-none btn-edit"
                          @click="onUpdate('save')"
                          text
                          :loading="loading"
                          v-if="activeProduct"
                          :disabled="clicked || blockedItem || item.id_state === '450' || !changed"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon dense class="pointer-events-auto">mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("btn.update") }}
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-0 pr-0 d-flex">
                    <VTextField
                      dense
                      outlined
                      :label="$t('form.ttn')"
                      :maxLength="14"
                      class="br-10 inputNumber-off"
                      v-model="inputCrmItem.ttn"
                      @input="offCodeSvg"
                      :disabled="
                        blockedItem ||
                          !watchDeliveryItem() ||
                          ttnCodeStatus ||
                          item.id_state === '440' ||
                          isSendedCrmOrder
                      "
                      color="success"
                      v-on:keypress="validationForNumbers($event)"
                      @paste="validationForNumbers($event)"
                    >
                    </VTextField>
                    <div class="ml-1">
                      <v-btn
                        style="width: 30px"
                        icon
                        v-if="ttnCodeStatus"
                        @click="copyToClipboard(inputCrmItem.ttn)"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="ttnCodeStatus"
                        :disabled="!watchDeliveryItem()"
                        icon
                        @click="delayTtn"
                        style="width: 30px"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                      <v-icon
                        class="mt-2"
                        :disabled="blockedItem || getUser?.is_np_apikey == '0'"
                        @click="onloadTTN"
                        v-else
                        >mdi-magnify
                      </v-icon>
                    </div>
                  </v-col>

                  <v-col cols="12" class="d-flex">
                    <v-row>
                      <v-col
                        :class="{
                          'col-7': settingDialog != 'crm',
                          'col-12': settingDialog == 'crm' || $vuetify.breakpoint.smAndDown
                        }"
                      >
                        <VSelect
                          outlined
                          dense
                          :items="payTypes"
                          item-value="value"
                          item-text="value"
                          class="br-10"
                          :label="$t('payType.label')"
                          hide-details="auto"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                          @change="onChangePayType"
                          v-model="inputCrmItem.pay_type"
                          :disabled="ttnCodeStatus || !watchDeliveryItem()"
                          color="success"
                          item-color="success"
                        >
                          <template slot="item" slot-scope="{ item }">
                            <span class="text-truncate">
                              {{ $t(`payType.${item.value}`) }}
                            </span>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span class="text-truncate">
                              {{ $t(`payType.${item.value}`) }}
                            </span>
                          </template>
                        </VSelect>
                      </v-col>
                      <v-col
                        :class="{
                          'col-5': settingDialog != 'crm',
                          'col-12 py-0': settingDialog == 'crm' || $vuetify.breakpoint.smAndDown
                        }"
                      >
                        <v-row no-gutters align="center">
                          <v-col
                            :class="{
                              'col-9': settingDialog == 'crm',
                              'col-12': settingDialog != 'crm'
                            }"
                          >
                            <VTextField
                              dense
                              outlined
                              @keypress="validationForPrice($event)"
                              @paste="validationForPrice($event)"
                              hide-details="auto"
                              :error-messages="deliveryPaymentMadeErrors"
                              :label="$t('form.payment_made')"
                              min="0"
                              class="br-10"
                              :disabled="
                                blockedItem ||
                                  !watchDeliveryItem() ||
                                  ttnCodeStatus ||
                                  item.id_state === '440' ||
                                  isSendedCrmOrder ||
                                  (inputCrmItem.pay_type != 'CASH_ON_DELIVERY' &&
                                    inputCrmItem.pay_type != 'PAYMENT_CONTROL') ||
                                  (isProductPriceCashOnDelivery && settingDialog !== 'supply')
                              "
                              v-model="inputCrmItem.cash_on_delivery"
                              color="success"
                              :maxlength="8"
                            />
                          </v-col>
                          <v-col class="col-3" v-if="settingDialog == 'crm'">
                            <v-switch
                              class="ml-2"
                              color="success"
                              hide-details
                              :disabled="
                                blockedItem ||
                                  ttnCodeStatus ||
                                  item.id_state === '440' ||
                                  isSendedCrmOrder ||
                                  (inputCrmItem.pay_type != 'CASH_ON_DELIVERY' &&
                                    inputCrmItem.pay_type != 'PAYMENT_CONTROL')
                              "
                              v-model="isProductPriceCashOnDelivery"
                            >
                            </v-switch>
                            <div style="font-size: 11px; line-height: 12px; margin-left: 2px">
                              Використати ціну товару
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters align="center">
                      <v-col cols="9" :class="{ 'col-12': settingDialog != 'crm' }">
                        <VTextField
                          dense
                          outlined
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                          hide-details
                          :label="$t('orderDialog.price')"
                          min="0"
                          class="br-10"
                          :disabled="
                            blockedItem ||
                              !watchDeliveryItem() ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder ||
                              (isProductPriceCost && settingDialog != 'supply')
                          "
                          v-model="inputCrmItem.cost"
                          color="success"
                          :maxlength="8"
                        />
                      </v-col>
                      <v-col class="col-3" v-if="settingDialog == 'crm'">
                        <v-switch
                          class="ml-2"
                          color="success"
                          hide-details
                          :disabled="
                            blockedItem ||
                              ttnCodeStatus ||
                              item.id_state === '440' ||
                              isSendedCrmOrder
                          "
                          v-model="isProductPriceCost"
                        >
                        </v-switch>
                        <div style="font-size: 11px; line-height: 12px; margin-left: 2px">
                          Використати ціну товару
                        </div>
                      </v-col>
                      <!-- <v-col cols="4">
                        <VTextField
                          dense
                          outlined
                          hide-details
                          :label="$t('form.weight')"
                          readonly
                          disabled
                          class="br-10"
                          v-model="inputCrmItem.weight"
                        />
                      </v-col>
                      <v-col cols="4">
                        <VTextField
                          dense
                          outlined
                          type="number"
                          hide-details
                          readonly
                          disabled
                          :label="$t('form.shipping_cost')"
                          v-model="inputCrmItem.delivery_price"
                          class="br-10"
                        />
                      </v-col> -->
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <div class="orderDialog__barCodeSvg" :class="{ active: ttnCodeSvg.length > 0 }">
                      <barcode :value="ttnCodeSvg" tag="svg" v-if="ttnCodeSvg.length"></barcode>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider v-if="item.id_state === '440' && returnedDeliveries.length" />
            <v-slide-x-reverse-transition>
              <v-row v-if="item.id_state === '440' && returnedDeliveries.length">
                <v-col cols="12">
                  <v-data-table
                    fixed-header
                    :headers="returnedDeliveries_headers"
                    :items="returnedDeliveries"
                    item-key="uuid"
                    hide-default-footer
                    :items-per-page="returnedDeliveries.length"
                    dense
                    class="my-table supply"
                    :no-data-text="$t('table.noData')"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.btn="{ item }">
                      <v-btn
                        class="btn-edit sm"
                        color="primary"
                        dark
                        depressed
                        small
                        @click="onSupplyInButton(item)"
                      >
                        <v-icon>
                          mdi-file-document-edit-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.state_details.uk_name="{ item }">
                      <div class="d-flex align-center justify-content-start ">
                        <StatusCircle :status-name="item.id_state" :type="'supply'" class="sm" />
                        <div class="elispsis">
                          {{ item.state_details[`${currentLanguage.key}_name`] }}
                        </div>
                      </div>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.date="{ item }">
                      {{ item.time_created | createdAt(item.time_created, currentLanguage.code) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-slide-x-reverse-transition>
          </v-tab-item>
          <v-tab-item>
            <keep-alive :max="1">
              <v-tabs-items v-model="productTab" :touchless="true">
                <v-tab-item value="edit">
                  <div class="orderDialog__selectedProducts">
                    <v-row no-gutters>
                      <!-- <v-row align="center" justify="start">
                        <v-col v-if="chosenProducts.length" cols="auto">
                          <VSubheader class="px-0">
                            <v-icon dense left>mdi-cube-scan</v-icon>
                            <div v-if="!$vuetify.breakpoint.smAndDown" class="mr-1">
                             {{ $t('supply.total_weight') }}
                            </div>
                            {{ metricsValue.weight_gross || 0 }}
                          </VSubheader>
                        </v-col>
                      </v-row> -->
                    </v-row>
                    <div class="orderDialog__nav pa-0" v-if="!permissions.client">
                      <v-btn
                        class="btn-tabNav mx-0"
                        @click="tabItemsStep = 0"
                        :class="{ active: tabItemsStep === 0 }"
                      >
                        <v-icon small :left="!$vuetify.breakpoint.smAndDown"
                          >mdi-text-box-check-outline</v-icon
                        >
                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          {{ $t("supply.chosen_items") }}
                        </div>
                      </v-btn>
                      <v-btn
                        class="btn-tabNav mx-0"
                        @click="tabItemsStep = 1"
                        :class="{ active: tabItemsStep === 1 }"
                      >
                        <v-icon small :left="!$vuetify.breakpoint.smAndDown"
                          >mdi-package-variant-closed</v-icon
                        >
                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          {{ $t("supply.sended_items") }}
                        </div>
                      </v-btn>
                      <v-btn
                        v-if="!permissions.client"
                        class="btn-tabNav mx-0"
                        @click="tabItemsStep = 2"
                        :class="{ active: tabItemsStep === 2 }"
                      >
                        <v-icon small :left="!$vuetify.breakpoint.smAndDown"
                          >mdi-table-merge-cells</v-icon
                        >
                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          {{ $t("supply.cell") }}
                        </div>
                      </v-btn>
                    </div>
                    <div>
                      <items-view
                        v-if="
                          (tabItemsStep === 0 && !permissions.client) ||
                            (item.id_state < 430 &&
                              permissions.client &&
                              settingDialog === 'supply') ||
                            settingDialog === 'crm'
                        "
                        type="chosenProducts"
                        :items="chosenProducts"
                        :loadingItems="loadingItems"
                        @showImg="showImg"
                        :isImgLoaded="isImgLoaded"
                        :blockedItem="blockedItem"
                        @onClickDelete="onClickDelete"
                        :watchDelivery="watchDeliveryItem()"
                        :isChangeAmount="watchDeliveryItem()"
                        @onChangeCounter="onChangeCounter"
                        @onChangePrice="onChangePrice"
                        :settingDialog="settingDialog"
                        :resetCopyItems="resetCopyItems"
                        :isAccessDeportInfo="isAccessDeportInfo"
                        :resetEditPriceTable="resetEditPriceTable"
                        :totalChosenProducts="totalChosenProducts"
                      />
                    </div>
                    <v-slide-x-transition hide-on-leave>
                      <items-view-tree-view
                        type="withDetails"
                        v-if="
                          tabItemsStep === 1 ||
                            (item.id_state >= 430 &&
                              permissions.client &&
                              settingDialog === 'supply')
                        "
                        :items="completedItems"
                        :loadingItems="loadingItems"
                        @showImg="showImg"
                        :isImgLoaded="isImgLoaded"
                        :blockedItem="blockedItem"
                        :watchDelivery="watchDeliveryItem()"
                      />
                    </v-slide-x-transition>
                    <v-slide-x-transition hide-on-leave>
                      <items-view-tree-view
                        type="withDetails"
                        v-if="tabItemsStep === 2 && !permissions.client"
                        :items="itemsInCell"
                        :loadingItems="loadingItems"
                        @showImg="showImg"
                        :isImgLoaded="isImgLoaded"
                        :blockedItem="blockedItem"
                        :watchDelivery="watchDeliveryItem()"
                      />
                    </v-slide-x-transition>
                  </div>
                </v-tab-item>
                <v-tab-item value="add">
                  <product-list-component
                    v-if="productTab === 'add'"
                    :settingDialog="settingDialog"
                    :item="item"
                    :imagesCheckbox="imagesCheckbox"
                    :availableItemsCheckbox="availableItemsCheckbox"
                    :isAccessDeportInfo="isAccessDeportInfo"
                    :inputCrmItem="inputCrmItem"
                    :isCRMProducts="isCRMProducts"
                    @onHandleChoosenProduct="onHandleChoosenProduct"
                  />
                </v-tab-item>
                <v-tab-item>product - 3</v-tab-item>
              </v-tabs-items>
            </keep-alive>
          </v-tab-item>
          <v-tab-item>
            <DeliveryChatComponent v-if="tabStep === 2" :isSendMsg="isSendMsg" :item="item" />
          </v-tab-item>
          <v-tab-item>
            <info-dialog-crm
              v-if="settingDialog === 'crm' && tabStep === 3"
              :data="inputCrmItem"
              :isAllowAssignedUserChange="isAllowAssignedUserChange"
              @onChangeAssignedUser="onChangeAssignedUser"
            ></info-dialog-crm>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider v-if="tabStep !== 2" class="mx-4" />
      <div class="d-flex">
        <v-checkbox
          class="ml-4"
          v-if="tabStep == 1 && productTab == 'add'"
          :label="$t('crm.show_pictures')"
          v-model="imagesCheckbox"
          hide-details
          color="success"
        ></v-checkbox>
        <v-checkbox
          class="ml-4"
          v-if="tabStep == 1 && productTab == 'add' && !isCRMProducts"
          :label="$t('cell.available_items')"
          v-model="availableItemsCheckbox"
          hide-details
          color="success"
        ></v-checkbox>
      </div>
      <v-card-actions
        :class="{ 'pa-0 pt-1': $vuetify.breakpoint.smAndDown, 'pt-0': tabStep === 2 }"
      >
        <v-row class="my-0 " align-items="self-end">
          <v-col
            ref="colElement"
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 8"
            class="pl-5 pb-1"
            style="padding-top: 1px;"
            :class="tabStep === 2 || $vuetify.breakpoint.smAndDown ? 'pr-2' : 'pr-0'"
          >
            <div class="text-center" v-if="activeProduct">
              <v-menu
                v-model="chatMenu"
                :close-on-content-click="false"
                :close-on-click="true"
                transition="slide-y-reverse-transition"
                origin="top center"
                :offset-y="true"
                nudge-bottom="0"
                nudge-top="450"
                nudge-right="150"
                nudge-left="150"
                rounded="lg"
                content-class="elevation-2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small plain block v-bind="attrs" v-on="on">
                    <v-icon :class="{'rotate-icon': chatMenu}">
                      mdi-chevron-up
                    </v-icon>
                  </v-btn>
                </template>
                <v-card flat >
                <v-card-text class="pa-1">
                  <DeliveryChatComponent :isSendMsg="isSendMsg" :item="item" v-if="chatMenu" />
                </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div v-if="activeProduct" class="telegram-textarea" ref="divElement">
              <VTextField
                solo
                dense
                :placeholder="$t('form.msg')"
                height="36px"
                hide-details
                v-model="inputMessage"
                @keyup.enter="onSendMsg"
                ref="inputMsg"
                :color="$vuetify.theme.dark ? 'grey lighten-3' : 'black'"
                :maxlength="255"
                :style="{ background: $vuetify.theme.dark ? '#1E1E1E' : '#F8F8FF' }"
              >
                <template v-slot:append>
                  <v-scroll-x-transition>
                    <v-btn
                      icon
                      :disabled="inputMessage.length === 0"
                      class="send-btn ml-3"
                      @click="onSendMsg"
                    >
                      <v-icon color="success" :size="25" transition="scale-transition">
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </v-scroll-x-transition>
                </template>
              </VTextField>
            </div>
            <div v-if="activeProduct" class="pl-3 pb-1">
              <span
                class="copyTxt"
                @click="
                  inputMessage += ' #додзвон';
                  focusInputField();
                "
              >
                {{ "#додзвон" }}
              </span>
              <span
                v-if="activeProduct"
                class="copyTxt"
                @click="
                  inputMessage += ' #не додзвон';
                  focusInputField();
                "
              >
                {{ "#не додзвон" }}
              </span>
            </div>
            <div ref="divElement2"></div>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
            class="my-0 pt-0 pl-0 pb-1  d-flex align-center"

          >
            <v-col
              v-if="Number(item.id_state) == 412 && !inputCrmItem.ttn && settingDialog === 'supply'"
              class="pb-0"
              cols="12"
            >
              <v-btn
                class="text-white text-transform-none br-10 w-100"
                color="success"
                :disabled="!inputCrmItem.ttn"
              >
                {{ $t("crm.ready_to_ship") }}
                <v-icon class="ml-1">
                  mdi-exclamation
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="Number(item.id_state) == 412 && inputCrmItem.ttn && settingDialog === 'supply'"
              class="pb-0"
              cols="12"
            >
              <v-btn
                class="text-white text-transform-none br-10 w-100"
                color="success"
                :disabled="changed"
                @click="setStateReadyToDel"
              >
                {{ $t("crm.ready_to_ship") }}
              </v-btn>
            </v-col>
            <v-col
              v-if="Number(item.id_state) < 401 && settingDialog === 'supply'"
              class="py-0"
              cols="12"
            >
              <v-btn
                class="text-white text-transform-none br-10 w-100"
                :class="{ 'success-bg': !sendButtonDisabled }"
                @click="showConfirmSend.visible = true"
                :disabled="changed || blockedItem || sendButtonDisabled"
              >
                {{ $t("btn.send_order") }}
                <v-icon small class="ml-2">mdi-cube-send</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="
                tabStep === 0 &&
                  !activeProduct &&
                  (permissions.client || permissions.crm_user) &&
                  settingDialog === 'crm'
              "
              class="pb-0"
              cols="12"
            >
              <v-btn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                @click="onCreate"
                :loading="loading"
                :disabled="!changed"
              >
                {{ $t("btn.create_order") }}
              </v-btn>
            </v-col>
            <v-col
              v-if="
                tabStep === 0 &&
                  !activeProduct &&
                  (permissions.client || permissions.crm_user) &&
                  settingDialog === 'supply'
              "
              class="pb-0"
              cols="12"
            >
              <v-btn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                @click="onCreate"
                :loading="loading"
                :disabled="!changed"
              >
                {{ $t("btn.create_order") }}
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="settingDialog === 'crm' && activeProduct" class="py-0">
              <v-btn
                class="text-white text-transform-none br-10 w-100 success-bg"
                block
                @click="showConfirmSend.visible = true"
                :disabled="
                  changed || sendButtonDisabled || !item.ready_to_deliver || !isAllowSendOrder
                "
              >
                {{ $t("btn.send_order") }}
                <v-icon small class="ml-2">mdi-cube-send</v-icon>
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
    <ReturnSuppply
      :visible="visibleReturnDialog"
      @close="visibleReturnDialog = false"
      :uuid_parent="item.uuid"
    />

    <ConfirmDialogSlots
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="
        onRemoveItem(chosenItem);
        confirmDialog = false;
      "
      @onCancel="confirmDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.accept_deleteDesc") }}
      </template>
    </ConfirmDialogSlots>

    <ConfirmDialogSlots
      :visible="showConfirmChangeStateDialog.visible"
      @close="
        showConfirmChangeStateDialog.visible = false;
        onCancelChangeState();
      "
      @onConfirm="onConfirmChangeState"
      @onCancel="
        showConfirmChangeStateDialog.visible = false;
        onCancelChangeState();
      "
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.confirmChangeStatusTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.confirmChangeStatusDesc") }}
        {{ `'${showConfirmChangeStateDialog?.nextState?.uk_name}'` }}
        {{ "?" }}
      </template>
    </ConfirmDialogSlots>

    <ConfirmDialogSlots
      :visible="showConfirmSend.visible"
      @close="showConfirmSend.visible = false"
      @onConfirm="
        onSend();
        showConfirmSend.visible = false;
      "
      @onCancel="showConfirmSend.visible = false"
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.confirmSend.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.confirmSend.desc") }}
      </template>
    </ConfirmDialogSlots>
    <GenerateTTNDialog
      v-if="generationTTNconfirm"
      :visible="generationTTNconfirm"
      :inputDelivery="inputDelivery"
      :inputAddress="inputAddress"
      :radioServiceType="radioServiceType"
      :inputCrmItem="inputCrmItem"
      :chosenProducts="chosenProducts"
      :counterparty="counterparty"
      @close="generationTTNconfirm = false"
      @onConfirm="generateTTN"
      @onCancel="generationTTNconfirm = false"
    >
    </GenerateTTNDialog>
    <ConfirmDialogSlots
      :visible="showTTNRewrite"
      @close="generationTTNconfirm = false"
      @onConfirm="
        isRewrite = true;
        onloadTTN();
        showTTNRewrite = false;
      "
      @onCancel="showTTNRewrite = false"
      :typeAlert="'info'"
    >
      <template v-slot:header>
        Пошук по ттн
      </template>
      <template v-slot:description>
        <div style="font-size: 16px;" class="font-weight-bold mb-2">
          При пошуку за ттн, наявні дані будуть перезаписані
        </div>
      </template>
    </ConfirmDialogSlots>

    <EditSupplyInDialog
      v-if="visibleSupplyIn"
      :visible="visibleSupplyIn"
      @close="visibleSupplyIn = false"
      :supply="SupplyInItem"
      hide-parent-item
    />
    <CallCenterDismissDialogVue
      :visible="visibleCallCenterDismissDialogVue"
      @close="closecallCenterDismiss"
      :list_states="user_states"
      :uuid_state="uuid_user_state"
      @noChange="closecallCenterDismiss"
      @changeState="setUserState"
      v-if="visibleCallCenterDismissDialogVue"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/crm/types/getters";
import * as mutations from "@/store/modules/novaPochta/types/mutations";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/modules/auth/types/actions";
import _ from "lodash";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import ConfirmDeleteDialog from "../dialog/ConfirmDeleteDialog.vue";
import headerDialogCrm from "./components/header/headerDialogCrm.vue";
import headerDialogSupply from "./components/header/headerDialogSupply.vue";
import infoDialogCrm from "./components/tabs/infoDialogCrm.vue";
import language from "../../mixins/language";
import imagesCheck from "../../mixins/imagesCheckMixin";
import availableItemsCheck from "@/mixins/itemsCheckMixin";
import notifications from "../../mixins/notifications";
import novaPochta from "@/mixins/novaPochta";
import user from "@/mixins/user";
import {
  noEngText,
  noEngTextNumber,
  onlyNumber,
  noEngTextPasted,
  validationForPhoneNumber,
  validationForName,
  validationForSurname,
  validationForDepartment,
  validationForCities,
  validationForHouses,
  validationForNumbers,
  validationForPrice,
  validationForStreets
} from "@/mixins/helpers";
import OrderMixin from "./OrderMixin";
import crmService from "../../services/request/crm/crmService";
import supplyService from "../../services/request/supply/supplyService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import novaPochtaService from "../../services/request/novaPochta/novaPochtaService";
import imgDialog from "@/components/products/imgDialog.vue";
import phoneMask from "@/validators/phoneMask";
import noEngTextValidator from "@/validators/noEngText";
import DeliveryChatComponent from "../chat/DeliveryChatComponent.vue";
import NsSmsIntegration from "../sms/ns-sms-integration.vue";
import ReturnSuppply from "./components/dialogs/ReturnSupply.vue";
import ConfirmDialogSlots from "../dialog/ConfirmDialog.vue";
import itemsView from "./components/tabs/ItemsView.vue";
import itemsViewTreeView from "@/components/supply/dialogs/itemViewTreeView.vue";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import EditSupplyInDialog from "@/components/supply/EditSupplyInDialog.vue";
import CallCenterDismissDialogVue from "./components/dialogs/CallCenterDismissDialog.vue";
import GenerateTTNDialog from "./components/dialogs/GenerateTTNDialog.vue";
import cashierDialog from "./components/dialogs/cashierDialog.vue";
import chooseCashierDialog from "./components/dialogs/chooseCashierDialog.vue";
import productListComponent from "@/components/orderDialog/components/tabs/productList/productListComponent.vue";
import localStorageMixin from "@/mixins/localStorage";
// const payTypes = [null,"CASH_ON_DELIVERY","PREPAY","PAYED","REJECT"]

export default {
  name: "OrderDialog",
  data: () => ({
    chatMenu: false,
    colWidth: 0,
    colPosition: {
      left: 0,
      top: 0
    },
    showHistoryDialog: false,
    searchWarehouseQuery: "",
    isTtn: true,
    disableRequiredFields: false,
    chosenProductsPagination: {
      page: 1
    },
    isSendMsg: false,
    inputMessage: "",
    stateIsChanged: false,
    clicked: false,
    isCRMProducts: false,
    isInsertData: false,
    totalChosenProducts: "",
    resetEditPriceTable: false,
    smsIntegrationMenu: false,
    visibleCallCenterDismissDialogVue: false,
    tabItemsStep: 0,
    onEngText: true,
    loadingChosenProducts: false,
    categoryChipTab: null,
    tableAvaibleProductsFilter: {},
    oldSerach: "",
    metricsValue: {},
    chosenItem: "",
    statusCreateTTN: false,
    copyStatusCreateTTN: false,
    changedStatus: false,
    loadingCreateTTN: false,
    confirmDialog: false,
    confirmDialogTtn: false,
    changed: false,
    productTab: "edit",
    loading: true,
    generateDialogVisible: false,
    generationTTNconfirm: false,
    listCounterparty: [],
    item: {},
    counterparty: {},
    counterpartyPhone: "",
    chosenProducts: [],
    completedItems: [],
    uuid_user_state: "",
    assignTo: "",
    searchString: "",
    activeProduct: false,
    tabStep: 0,
    ttnCodeSvg: "",
    ttnCodeStatus: false,
    inputDelivery: {
      name: "",
      surname: "",
      middlename: "",
      phone: "",
      city: "",
      name_city: "",
      warehouse: {},
      name_warehouse: "",
      warehouse_id: ""
    },
    showTTNRewrite: false,
    delivery: {
      phoneMenu: false,
      items: []
    },
    inputSender: {
      name: "",
      surname: "",
      middlename: "",
      phone: ""
    },
    sender: {
      phoneMenu: false,
      items: []
    },
    inputCrmItem: {
      description: "",
      ttn: "",
      cost: null,
      uuid: "",
      cash_on_delivery: null,
      is_blocked: false,
      id_state: "400",
      delivery_price: "",
      weight: "",
      pay_type: null
    },
    visibleSupplyIn: false,
    SupplyInItem: {},
    states: [],
    filteredStates: [],
    copySender: {},
    copyDelivery: {},
    copyAddress: {
      check: true,
      city_address: {},
      city_street: {},
      city_house: "",
      city_flat: ""
    },
    copyCrmItem: {},
    visibleConfirm: false,
    isImgLoaded: false,
    page: 1,
    perPage: 10,
    loadMoreDisabled: false,
    visibleReturnDialog: false,
    cancelReturn: false,
    generateTtnVisible: false,
    showConfirmChangeStateDialog: {
      visible: false
    },
    showConfirmSend: {
      visible: false
    },
    isRewrite: false,
    loadingItems: false,
    itemsInCell: [],
    returnedDeliveries: [],
    returnedDeliveries_headers: [],
    resetCopyItems: false,
    isLoadingCity: false,
    changedSender: false,
    changedDelivery: false,
    changedCrmItem: false,
    changedAddress: false,
    changedAssigned: false,
    changedStatusCreateTTN: false,
    radioServiceType: "WarehouseWarehouse",
    copyRadioServiceType: "",
    searchCityQuery: "",
    cityAddressLoading: false,
    windowHeight: document.documentElement.clientHeight,
    pagination: {
      page: 1,
      perPage: 50
    },
    movement: {
      check: false,
      address: ""
    },
    isProductPriceCashOnDelivery: true,
    isProductPriceCashOnDeliveryCopy: true,
    isProductPriceCost: true,
    isProductPriceCostCopy: true,
    loadMoreCitiesDisabled: false,
    searchAddressCityQuery: "",
    settlementsList: [],
    streetsList: [],
    inputAddress: {
      check: true,
      city_address: {},
      city_street: {},
      city_house: "",
      city_flat: ""
    },
    paginationStreets: {
      page: 1,
      perPage: 50
    },
    uuid_user_assigned_to: "00000000-0000-0000-0000-000000000000",
    copy_uuid_user_assigned_to: "00000000-0000-0000-0000-000000000000",
    loadMoreStreetsDisabled: false,
    searchAddressStreetQuery: null,
    imagesCheckbox: true,
    availableItemsCheckbox: true,
    isAvailableItems: true,
    payTypes: [
      {
        value: null
      },
      {
        value: "CASH_ON_DELIVERY"
      },
      {
        value: "PREPAY"
      },
      {
        value: "PAYED"
      },
      {
        value: "REJECT"
      }
    ],
    cashierDialogMenu: false,
    chooseCashierDialog: false
  }),
  beforeDestroy() {
    window.removeEventListener("resize", this.getColWidth);
    window.removeEventListener("resize", this.calculateColPosition);
  },
  async mounted() {
    window.addEventListener("resize", this.getWindowHeight);
    this.$v.$reset();
    EventBus.$on("on-handle-product-tab", chosenTab => {
      this.productTab = chosenTab;
    });
    EventBus.$on("on-handle-chosen-product", uuid => {
      this.chosenProducts = [];
      this.getChosenProducts(uuid);
      // this.getMetrics()
      this.productTab = "edit";
    });
    EventBus.$on("on-returned", () => {
      EventBus.$emit("supply-out-modified");
      this.visibility = false;
      // EventBus.$emit("close-dialog");
      this.onClose();
    });
    if (this.typeDialog === "add") {
      const StoreStatusCreateTTN = JSON.parse(localStorage.getItem("statusCreateTTN"));
      this.statusCreateTTN = StoreStatusCreateTTN;
      if (this.getUser?.is_np_apikey == "1") {
        this.counterpartyList();
      }
    } else {
      if (this.getUser?.is_np_apikey == "1") {
        this.counterpartyList();
        this.statusCreateTTN = true;
      }
    }

    try {
      await this.fetchData();
      if (this.settingDialog === "crm" && this.item.is_reviewed === "1" && !this.isCallCenter) {
        EventBus.$emit("crm-items-reset");
      }
    } catch (e) {
      console.log(e);
    }
    this.returnedDeliveries_headers = [
      {
        text: "",
        align: "center",
        sortable: false,
        value: "btn"
      },
      {
        text: "#",
        align: "center",
        sortable: false,
        value: "number"
      },
      {
        text: "ТТН",
        sortable: false,
        value: "ttn"
      },
      {
        text: this.$t("table.status"),
        sortable: false,
        value: "state_details.uk_name"
      },
      {
        text: this.$t("table.date"),
        sortable: false,
        value: "date"
      }
    ];
    window.addEventListener("resize", this.getColWidth);
    window.addEventListener("resize", this.calculateColPosition);
    this.getColWidth();
    this.calculateColPosition();
  },
  created() {
    EventBus.$on("confirm-dialog", this.showConfirmDialog);
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    EventBus.$off("confirm-dialog", this.showConfirmDialog);
    EventBus.$off("on-handle-product-tab", this.showConfirmDialog);
    EventBus.$off("on-handle-chosen-product", this.showConfirmDialog);
    EventBus.$off("on-returned", this.showConfirmDialog);
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    onHandleChoosenProduct(uuid) {
      this.chosenProducts = [];
      this.getChosenProducts(uuid);
      // this.getMetrics()
      this.productTab = "edit";
    },
    chooseCashierDialogClose(result) {
      if(result != "00000000-0000-0000-0000-000000000000"){
        this.item.uuid_receipt = result;
      }
      this.chooseCashierDialog = false;
    },
    calculateColPosition() {
      const rect = this.$refs.divElement2.getBoundingClientRect();
      this.colPosition.left = (rect.left / window.innerWidth) * 100;
      this.colPosition.top = ((rect.top + 5) / window.innerHeight) * 100;
    },
    getColWidth() {
      const divElement = this.$refs.divElement;
      if (divElement) {
        const rect = divElement.getBoundingClientRect();
        this.colWidth = rect.width;
      }
    },
    focusInputField() {
      this.inputMessage += " ";
      this.$refs.inputMsg.focus();
    },
    async onSendMsg() {
      try {
        if (this.inputMessage) {
          const formData = new FormData();
          formData.append("uuid_object", this.item.uuid);
          formData.append("message", this.inputMessage);
          this.inputMessage = "";
          await supplyService.chatMessage(formData);
          this.isSendMsg = !this.isSendMsg;
          // if (this.tabStep === 2) {
          // }
        }
      } catch (e) {
        console.log(e);
      }
    },
    validationForStreets,
    validationForPrice,
    validationForNumbers,
    validationForHouses,
    validationForCities,
    validationForDepartment,
    validationForSurname,
    validationForName,
    validationForPhoneNumber,
    ...mapActions("auth", {
      changeUserData: actionTypes.CHANGE_USER_DATA_BY_KEY
    }),
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    handleImageCheckBox(value) {
      if (this.isCRMProducts) {
        this.fetchCrmCheck(value);
      } else {
        this.fetchDeliveriesCheck(value);
      }
    },
    handleItemsCheckBox(value) {
      if (this.isCRMProducts) {
        this.fetchCrmItemsCheck(value);
        this.isAvailableItems = this.fetchCrmItemsCheck(value);
      } else {
        this.fetchDeliveriesItemsCheck(value);
        this.isAvailableItems = this.fetchDeliveriesItemsCheck(value);
      }
    },
    changeUserDataHandler({ key, value }) {
      if (key === "deliveries_balance" && value <= 0) {
        // this.setWarningNotification(this.$t("supply.pay_balance"));
      }
      this.changeUserData({ key: value });
    },
    onDeliveryOutsideClick() {
      if (this.delivery.phoneMenu == true) {
        this.delivery.phoneMenu = false;
      }
    },
    onSenderOutsideClick() {
      if (this.sender.phoneMenu == true) {
        this.sender.phoneMenu = false;
      }
    },
    pushToNPInfo() {
      window.open(
        `https://my.novaposhta.ua/orders/printDocument/orders/${
          this.inputCrmItem.ttn
        }/type/pdf/apiKey/${this.getUserProperty("np_apikey")}`
      );
    },
    copyToClipboard(e) {
      navigator.clipboard.writeText(e);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    onFocusCitiesHandler() {
      if (!this.activeCities.length) {
        this.onFocusCities(false);
      }
    },
    OnChangeStatusCreateTTN() {
      localStorage.setItem("statusCreateTTN", JSON.stringify(this.statusCreateTTN == 1));
    },
    onChangeCity(city, type) {
      this.warehousePagination.page = 1;
      if (this.settingDialog === "crm" && this.inputCrmItem.uuid) {
        this.onSelectCity(city, type, this.inputCrmItem.uuid);
      } else {
        this.onSelectCity(city, type);
      }
      this.searchAddressCityQuery = "";
      this.searchAddressStreetQuery = "";
      this.inputAddress.city_address = {};
      this.inputAddress.city_street = {};
      this.inputAddress.city_house = "";
      this.inputAddress.city_flat = "";
    },
    onChangePayType(e) {
      if (!e) {
        // this.inputCrmItem.pay_type = null;
      }
      if (e != "CASH_ON_DELIVERY") {
        this.inputCrmItem.cash_on_delivery = null;
      } else {
        if (
          this.isProductPriceCashOnDelivery &&
          (this.inputCrmItem.pay_type == "CASH_ON_DELIVERY" ||
            this.inputCrmItem.pay_type == "PAYMENT_CONTROL")
        ) {
          this.inputCrmItem.cash_on_delivery = this.getTotalPrice;
        } else {
          this.inputCrmItem.cash_on_delivery = null;
        }
      }
    },
    filterCities(item, queryText, itemText) {
      let searchArray = itemText.split("(");

      if (searchArray.length == 1) {
        searchArray = itemText.split(",");
      }
      return searchArray[0].toLowerCase().includes(queryText.toLowerCase());
    },
    nextPageCities() {
      this.cityPagination.page += 1;
      this.onFocusCities(true);
      this.isLoadingCity = true;
    },

    onFocusCities: _.debounce(async function(nextPage = false) {
      this.isLoadingCity = true;

      const data = { ...this.cityPagination };

      if (this.settingDialog === "crm" && this.inputCrmItem.uuid) {
        data.uuid_order = this.inputCrmItem.uuid;
      }
      if (this.settingDialog === "crm" && this.uuid_board) {
        data.uuid_board = this.uuid_board;
      }

      await this.fetchNovaPochtaCities(data);

      if (nextPage) {
        this.activeCities.push(...this.cities);
      } else {
        this.activeCities = this.cities;
      }
      this.cityPagination.isLoadDisabled = this.cities.length < this.cityPagination.perPage;

      this.isLoadingCity = false;
    }, 400),
    async sendMSG(message) {
      try {
        if (message) {
          this.tabStep = 2;
          const formDataMSG = new FormData();
          formDataMSG.append("uuid_object", this.inputCrmItem.uuid);
          formDataMSG.append("message", message);
          await supplyService.chatMessage(formDataMSG);
        }
      } catch (e) {
        console.log(e);
      }
    },
    closecallCenterDismiss() {
      this.visibleCallCenterDismissDialogVue = false;
      this.item.is_call_required = "0";
      this.onChangeCallCenter();
    },
    callRequiredUpdate() {
      if ((!this.changed && !this.watchDelivery()) || this.isSendedCrmOrder) return;
      if (this.item.is_call_required === "0") {
        this.item.is_call_required = "1";
      } else {
        this.visibleCallCenterDismissDialogVue = true;
        return;
      }

      this.onChangeCallCenter();
    },
    onChangeCounter: _.debounce(async function({ item, amountBeforeChange = 0 }) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", item.uuid);
        formData.append("items_amount", item.items_amount);

        if (this.settingDialog === "crm") {
          let additionalAmount = 0;
          const diff = item.items_amount - amountBeforeChange;
          if (amountBeforeChange !== item.items_amount) {
            if (diff > 0) {
              additionalAmount = Number(item.additional_sell_amount) + diff;
            } else if (diff <= 0 || item.items_amount <= Number(item.additional_sell_amount)) {
              additionalAmount = item.items_amount;
            }
            formData.append("additional_sell_amount", additionalAmount);
          }
          await crmService.ChangeItemValue(formData);
          const res = await crmService.isReadyToDeliver(this.item.uuid);
          this.item.ready_to_deliver = res.ready_to_deliver;
        } else {
          await supplyService.ChangeItemValue(formData);
        }
        this.chosenProducts = [];
        this.getChosenProducts(this.item.uuid);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    }, 100),
    async onChangePrice({ item, priceBeforeChange = 0 }, typePrice = null) {
      try {
        const formData = new FormData();
        formData.append("uuid", item.uuid);
        if (typePrice === "extra") {
          formData.append("price_extra", item.item_price_extra || "0.00");
        } else {
          formData.append("price", item.item_price || "0.00");
        }
        await crmService.ChangeItemPrice(formData);
        EventBus.$emit("supply-out-modified");
        this.chosenProducts = [];
        await this.getChosenProducts(this.item.uuid);
        this.resetEditPriceTable = !this.resetEditPriceTable;
        this.resetCopyItems = !this.resetCopyItems;
      } catch (e) {
        this.loading = false;
        console.log(e);
        this.resetEditPriceTable = !this.resetEditPriceTable;
        this.resetCopyItems = !this.resetCopyItems;
      }
    },

    // keyDownHandler(e) {
    //   if (!this.activeProduct) return;
    //   if (e.shiftKey && e.keyCode === 49) {
    //     this.tabStep = 0;
    //   }
    //   if (e.shiftKey && e.keyCode === 50) {
    //     this.tabStep = 1;
    //   }
    //   if (e.shiftKey && e.keyCode === 51) {
    //     this.tabStep = 2;
    //   }
    // },
    onSupplyInButton(item) {
      this.SupplyInItem = item;
      this.visibleSupplyIn = true;
    },
    onClickAddNewProducts() {
      // this.isAdditionSell = false;
      // this.isStandalone = false;
      this.imagesCheckbox = this.getStorageItem(`image_check__supply`);
      this.availableItemsCheckbox = this.getStorageItem(`items_check__supply`);
      this.isCRMProducts = false;
      this.productTab = "add";
      this.searchString = "";

      // this.getChipTabsItems()
    },
    onClickAddCRMProducts() {
      // this.isAdditionSell = false;
      // this.isStandalone = false;
      this.imagesCheckbox = this.getStorageItem(`image_check__crm`);
      this.availableItemsCheckbox = this.getStorageItem(`items_check__crm`);
      this.isCRMProducts = true;
      this.productTab = "add";
      this.searchString = "";

      // this.getChipTabsItems()
    },

    onClickMore() {
      const route = this.$router.resolve({
        name: "supply-details",
        params: {
          id: this.item.uuid,
          type: "supply",
          lang: this.currentLanguage.key
        }
      });
      window.open(route.href, "_blank");
    },
    onDeleteSupply({ data: { message } }) {
      const uuid = null;
      // if(message){
      //   uuid = message.split(' ')[3]
      // }
      EventBus.$emit("supply-out-modified"); // to do supply-out-remove
      this.visibility = false;
    },
    noEngText,
    noEngTextNumber,
    onlyNumber,
    noEngTextPasted,

    switchStatusNP() {
      this.inputSender = {
        name: "",
        surname: "",
        middlename: "",
        phone: ""
      };
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    onCancelChangeState() {
      this.cancelReturn = true;
    },
    onConfirmChangeState() {
      this.inputCrmItem.id_state = this.showConfirmChangeStateDialog.nextState.id;
      this.showConfirmChangeStateDialog.visible = false;
      this.onUpdateState(true);
      this.stateIsChanged = true;
    },
    async setOrderState(state) {
      if (this.inputCrmItem.id_state !== state.id) {
        if (state.confirm_required) {
          this.showConfirmChangeStateDialog = {
            nextState: state,
            visible: true
          };
          return;
        }
        this.inputCrmItem.id_state = state.id;
        this.onUpdateState(true);
      }
    },
    async setStateReadyToDel() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.inputCrmItem.id_state = 420;
          await this.onUpdateState(true);
          this.stateIsChanged = true;
          this.onClose();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async setUserState(uuid, message) {
      if (this.settingDialog === "crm") {
        try {
          if (message) {
            this.uuid_user_state = uuid;
            // orderDialog('setUserState');
            const formData = new FormData();
            formData.append("uuid_order", this.inputCrmItem.uuid);
            formData.append("uuid_state", this.uuid_user_state);
            this.loading = true;
            if (this.uuid_user_state != uuid) {
              await crmService.setUserState(formData);
            }
            this.loading = false;
            this.sendMSG(message);
            // EventBus.$emit("crm-items-reset");
          } else if (uuid) {
            // orderDialog('setUserState');
            const formData = new FormData();
            formData.append("uuid_state", uuid);
            formData.append("uuid_order", this.inputCrmItem.uuid);
            this.loading = true;
            await crmService.setUserState(formData);
            this.loading = false;
          } else {
            this.closecallCenterDismiss(message);
          }
          // orderDialog('newState', newState);
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      }
    },
    checkChanged(type) {
      if (this.changedStatus) {
        if (
          this.permissions.client &&
          Number(this.inputCrmItem.id_state) !== 400 &&
          this.settingDialog === "supply"
        ) {
          this.changed = false;
        } else {
          if (type === "sender") {
            this.changedSender = !_.isEqual(this.inputSender, this.copySender);
          }
          if (type === "delivery") {
            this.changedDelivery = !_.isEqual(this.inputDelivery, this.copyDelivery);
          }
          if (type === "crm_item" && !this.changedStatusCreateTTN) {
            this.changedCrmItem = !_.isEqual(this.inputCrmItem, this.copyCrmItem);
          }
          if (type === "address") {
            //
            this.changedAddress = !_.isEqual(this.inputAddress, this.copyAddress);
          }
          if (type === "assigned") {
            this.changedAssigned =
              this.copy_uuid_user_assigned_to == this.uuid_user_assigned_to ? false : true;
          }
          if (type === "status") {
            this.changedStatusCreateTTN =
              this.copyStatusCreateTTN == this.statusCreateTTN ? false : true;
          }
          let changedCashOnDelivery = false;
          let changedPriceCost = false;
          if (type === "cashOnDelivery") {
            changedCashOnDelivery = !(
              this.isProductPriceCashOnDelivery == this.isProductPriceCashOnDeliveryCopy
            );
          }
          if (type === "priceCost") {
            changedPriceCost = !(this.isProductPriceCost == this.isProductPriceCostCopy);
          }

          this.changed =
            this.changedSender ||
            this.changedDelivery ||
            this.changedCrmItem ||
            this.changedAddress ||
            this.changedAssigned ||
            this.changedStatusCreateTTN ||
            changedCashOnDelivery ||
            changedPriceCost;
        }
      } else {
        this.changed = false;
      }
    },
    delayTtn() {
      this.movement.check = false;

      // this.inputCrmItem.ttn = "";
      this.ttnCodeStatus = false;
      this.changedStatus = true;
    },
    onClose() {
      this.inputDelivery = null;
      this.listCounterparty = [];
      this.counterparty = null;
      this.activeCities = null;
      this.settlementsList = null;
      // EventBus.$emit("supply-update-list");

      if (this.stateIsChanged && this.settingDialog === "supply") {
        EventBus.$emit("supply-out-modified");
        this.stateIsChanged = false;
      }
      EventBus.$emit("close-dialog");
      this.$v.$reset();
      this.visibility = false;
      this.dataReset();
      this.$emit("resetSearchField");
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    async counterpartyList() {
      try {
        const params = {
          np_apikey: this.getUserProperty("np_apikey")
        };
        const response = await novaPochtaService.getCounterpartyList(params);
        // eslint-disable-next-line prefer-destructuring
        const defaultCounterparty = response.filter(item => Number(item.is_default) === 1);
        this.counterparty = defaultCounterparty[0];

        this.listCounterparty = response;
        if (!this.listCounterparty.length) {
          this.statusCreateTTN = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    watchDelivery() {
      // orderDialog('this.permissions.client', this.permissions.client);
      // orderDialog('this.permissions.client', this.inputCrmItem.id_state);
      if (
        (this.settingDialog === "supply" &&
          this.permissions.client &&
          Number(this.inputCrmItem.id_state) !== 400) ||
        (this.settingDialog === "supply" &&
          !this.permissions.client &&
          Number(this.inputCrmItem.id_state) >= 430)
      ) {
        return false;
      }
      // if (
      // this.settingDialog === "crm" &&
      // this.permissions.client &&
      // Number(this.inputCrmItem.id_state) > 0
      // ) {
      //  return false;
      // }
      return true;
    },
    watchDeliveryItem() {
      // orderDialog('this.permissions.client', this.permissions.client);
      // orderDialog('this.permissions.client', this.inputCrmItem.id_state);
      if (
        (this.settingDialog === "supply" &&
          this.permissions.client &&
          Number(this.inputCrmItem.id_state) !== 400) ||
        (this.settingDialog === "supply" &&
          !this.permissions.client &&
          Number(this.inputCrmItem.id_state) >= 420)
      ) {
        return false;
      }
      if (this.settingDialog === "crm") {
        return this.item.type !== "out";
      }
      return true;
    },
    async onRemoveItem(uuid) {
      // orderDialog(uuid);set
      const status = await this.onDelayProduct(uuid);
      if (status) {
        this.chosenProducts = this.chosenProducts.filter(item => item.uuid !== uuid);
        const res = await crmService.isReadyToDeliver(this.item.uuid);
        this.item.ready_to_deliver = res.ready_to_deliver;
        // if(!this.permissions.client) return // to remove when unique uuid
        EventBus.$emit("supply-out-modified", this.item, true);
      }
    },
    onClickDelete(uuid) {
      this.chosenItem = uuid;
      this.confirmDialog = true;
    },
    async onDelayProduct(uuid) {
      try {
        if (this.settingDialog === "supply") {
          await supplyService.deleteDeliveryItem(uuid);
          this.setSuccessNotification(this.$t("orderDialog.delayProduct_success"));
          // this.getProducts();
          // this.getMetrics();
        } else if (this.settingDialog === "crm") {
          this.loadingChosenProducts = true;
          await crmService.deleteCrmItem(uuid);
          await this.getChosenProducts(this.item.uuid);
          this.loadingChosenProducts = false;
          this.resetCopyItems = !this.resetCopyItems;

          this.setSuccessNotification(this.$t("orderDialog.delayProduct_success"));
        }
        return true;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(this.$t("orderDialog.delayProduct_error"));
        console.error("fail-delayProduct", e);
        return false;
      }
    },
    async fetchData() {
      this.dataReset();
      try {
        if (this.typeDialog === "edit") {
          // // orderDialog('this.typeDialog = ', this.typeDialog);
          let response = {};
          if (this.settingDialog === "crm") {
            response = await crmService.getCrmOrderItem(this.uuidItem);
          } else if (this.settingDialog === "supply") {
            response = await supplyService.getSupplyItem(this.uuidItem);
          }
          if (response) {
            this.item = response;
            this.isTtn = this.item.ttn > 0 && this.item.ttn !== null ? true : false;
            this.dataInsertion(this.item);
            if (this.item.id_state === "440") {
              const res = await supplyService.getSupplyList({
                uuid_parent: this.item.uuid
              });
              this.returnedDeliveries = res.object;
            }
            if (this.item.id_state >= 430 && this.settingDialog === "supply") {
              this.getCompletedProducts(this.item.uuid);
            }
            if (
              this.item.id_state < 430 ||
              !this.permissions.client ||
              this.settingDialog === "crm"
            ) {
              this.getChosenProducts(this.item.uuid, true);
            }
            if (this.inputDelivery.phone) {
              this.inputDelivery.phone = this.inputDelivery.phone.replace("+38", "");
            }
            if (this.inputSender.phone) {
              this.inputSender.phone = this.inputSender.phone.replace("+38", "");
            }
          }
        } else if (this.typeDialog === "add") {
          this.loading = false;
          this.copySender = {
            ...this.inputSender
          };

          this.copyAddress = {
            ...this.inputAddress
          };
          this.copyDelivery = {
            ...this.inputDelivery
          };
          this.copyCrmItem = {
            ...this.inputCrmItem
          };
          await this.$store.commit(`novaPochta/${mutations.DELAY_WAREHOUSES}`);
          this.changedStatus = true;
          // // orderDialog('this.typeDialog = ', this.typeDialog);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async onCreate() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          // formData.append('name', `Вихід ${moment.now()}`);
          formData.append("description", this.inputCrmItem?.description);

          if (this.uuid_tab.length) {
            formData.append("uuid_user_state", this.uuid_tab);
          }

          formData.append("delivery_address_details__name", this.inputDelivery.name);
          formData.append("delivery_address_details__surname", this.inputDelivery.surname);
          formData.append(
            "delivery_address_details__middlename",
            this.inputDelivery.middlename ? this.inputDelivery.middlename : ""
          );
          if (this.inputDelivery.phone) {
            formData.append("delivery_address_details__phone", `+38${this.inputDelivery.phone}`);
          } else {
            formData.append("delivery_address_details__phone", "");
          }

          if (this.radioServiceType == "WarehouseWarehouse") {
            formData.append("delivery_address_details__service_type", this.radioServiceType);
            formData.append(
              "delivery_address_details__uuid_ref_warehouse",
              this.inputDelivery.warehouse?.Ref
            );
            formData.append("delivery_address_details__uuid_ref_city", this.inputDelivery.city);
            formData.append("delivery_address_details__name_city", this.inputDelivery.name_city);
            formData.append(
              "delivery_address_details__name_warehouse",
              this.inputDelivery.name_warehouse
            );
            formData.append(
              "delivery_address_details__category_of_warehouse",
              this.inputDelivery.warehouse.CategoryOfWarehouse
            );
          } else {
            formData.append(
              "delivery_address_details__uuid_ref_street",
              this.inputAddress.city_street.Ref
            );
            formData.append(
              "delivery_address_details__uuid_ref_city",
              this.inputAddress.city_address.Ref
            );
            formData.append("delivery_address_details__name_city", this.searchAddressCityQuery);
            formData.append("delivery_address_details__name_street", this.searchAddressStreetQuery);
            formData.append("delivery_address_details__service_type", this.radioServiceType);
            formData.append("delivery_address_details__number_flat", this.inputAddress.city_flat);
            formData.append("delivery_address_details__number_house", this.inputAddress.city_house);
          }
          formData.append("sender_address_details__name", this.inputSender.name);
          formData.append("sender_address_details__surname", this.inputSender.surname);
          formData.append(
            "sender_address_details__middlename",
            this.inputSender.middlename ? this.inputSender.middlename : ""
          );
          if (this.inputSender.phone) {
            formData.append("sender_address_details__phone", `+38${this.inputSender.phone}`);
          } else {
            formData.append("sender_address_details__phone", "");
          }

          formData.append("is_auto_cash_on_delivery", this.isProductPriceCashOnDelivery ? 1 : 0);
          formData.append("is_auto_cost", this.isProductPriceCost ? 1 : 0);

          if (this.inputCrmItem.ttn) {
            formData.append(
              "ttn",
              this.inputCrmItem.ttn == 0 || this.inputCrmItem.ttn == ""
                ? null
                : this.inputCrmItem.ttn
            );
          } else {
            formData.append("ttn", null);
          }
          if (this.inputCrmItem.cost) {
            formData.append("cost", this.inputCrmItem.cost);
          }
          if (this.inputCrmItem.cash_on_delivery) {
            formData.append("cash_on_delivery", this.inputCrmItem.cash_on_delivery);
          }
          formData.append("is_counterparty_used", this.statusCreateTTN ? 1 : 0);
          formData.append("pay_type", this.inputCrmItem.pay_type);

          let response;
          if (this.settingDialog === "supply") {
            formData.append("type", "out");
            formData.append("uuid_user", this.getUserProperty("uuid"));
            response = await supplyService.addSupplyItem(formData);
            EventBus.$emit("supply-out-modified",response);

          } else if (this.settingDialog === "crm") {
            formData.append("uuid_board", this.uuid_board);
            response = await crmService.addCrmOrder(formData);

            EventBus.$emit("crm-items-reset");
            EventBus.$emit("get-speed-lead");
          }

          this.dataInsertion(response);
          if (this.settingDialog === "crm") {
            this.$refs.header_crm.createAllTags();
          }

          this.setSuccessNotification(this.$t("supply.successfully_created"));
          this.loading = false;
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e?.error?.type);
        }
      }
    },
    async onChangeAssignedUser(uuid) {
      this.uuid_user_assigned_to = uuid;
      let response;
      const formData = new FormData();
      if (
        this.settingDialog === "supply" &&
        this.copy_uuid_user_assigned_to != this.uuid_user_assigned_to
      ) {
        formData.append("uuid", this.inputCrmItem.uuid);
        formData.append("uuid_user_assigned_to", this.uuid_user_assigned_to);
        response = await supplyService.updateSupplyItem(formData);
        this.copy_uuid_user_assigned_to = this.uuid_user_assigned_to;
        this.checkChanged("assigned");
      } else if (
        this.settingDialog === "crm" &&
        this.copy_uuid_user_assigned_to != this.uuid_user_assigned_to
      ) {
        formData.append("uuid", this.inputCrmItem.uuid);
        formData.append("uuid_user_assigned_to", this.uuid_user_assigned_to);
        response = await crmService.updateCrmOrder(formData);
        this.copy_uuid_user_assigned_to = this.uuid_user_assigned_to;
        this.checkChanged("assigned");
      }
    },
    async onChangeCallCenter() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", this.inputCrmItem.uuid);
        formData.append("is_call_required", this.item.is_call_required);
        let response;
        if (this.settingDialog === "crm") {
          response = await crmService.updateCrmOrder(formData);
          this.dataInsertion(response);
          // EventBus.$emit("crm-items-reset");
        }
        EventBus.$emit("crm-items-reset");
        this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));

        if (this.$route.name == "call-center") {
          EventBus.$emit("onChangeCallCenter");
        }
        this.loading = false;
        this.$v.$reset();
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async onUpdate(mode) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.clicked = true;
          this.loading = true;
          const formData = new FormData();

          formData.append("uuid", this.inputCrmItem.uuid);
          // formData.append("uuid_deport", this.inputCrmItem.uuid_deport);

          if (this.isProductPriceCashOnDelivery != this.isProductPriceCashOnDeliveryCopy) {
            formData.append("is_auto_cash_on_delivery", this.isProductPriceCashOnDelivery ? 1 : 0);
          }

          if (this.isProductPriceCost != this.isProductPriceCostCopy) {
            formData.append("is_auto_cost", this.isProductPriceCost ? 1 : 0);
          }

          const changedDataSender = this.$getChangedData(this.inputSender, this.copySender);
          changedDataSender.forEach(data => {
            let prefix = "sender_address_details__";
            if (data === "description") {
              prefix = "";
            }
            formData.append(`${prefix}${data.field}`, data.value);
          });

          const changedDataCrmItem = this.$getChangedData(this.inputCrmItem, this.copyCrmItem);
          changedDataCrmItem.forEach(data => {
            formData.append(`${data.field}`, data.value);
          });
          const changedStatusCreateTTN = this.$getChangedData(
            { is_counterparty_used: this.statusCreateTTN },
            { is_counterparty_used: this.copyStatusCreateTTN }
          );
          changedStatusCreateTTN.forEach(data => {
            formData.append(`${data.field}`, data.value ? 1 : 0);
          });

          const changedDataDelivery = this.$getChangedData(this.inputDelivery, this.copyDelivery);
          changedDataDelivery.forEach(data => {
            let prefix = "";
            switch (data.field) {
              case "name":
              case "surname":
              case "middlename":
              case "phone":
                prefix = "delivery_address_details__";
                break;
              case "name_city":
              case "name_warehouse":
                prefix = "delivery_address_details__";
                break;
              case "city":
                if (this.radioServiceType == "WarehouseWarehouse") {
                  formData.append("delivery_address_details__uuid_ref_city", data.value);
                }
                break;
              case "warehouse":
                if (this.radioServiceType == "WarehouseWarehouse") {
                  formData.append(
                    "delivery_address_details__uuid_ref_warehouse",
                    data.value.Ref ? data.value.Ref : "00000000-0000-0000-0000-000000000000"
                  );
                  formData.append(
                    "delivery_address_details__category_of_warehouse",
                    data.value?.CategoryOfWarehouse
                  );
                }
                break;
              default:
                prefix = "";
            }

            if (data.field != "city" && data.field != "warehouse") {
              formData.append(`${prefix}${data.field}`, data.value);
            }
          });

          const changedDataAddress = this.$getChangedData(this.inputAddress, this.copyAddress);
          changedDataAddress.forEach(data => {
            switch (data.field) {
              case "city_address":
                if (this.radioServiceType == "DoorsWarehouse") {
                  formData.append("delivery_address_details__uuid_ref_city", data.value.Ref);
                  formData.append(
                    "delivery_address_details__name_city",
                    this.searchAddressCityQuery
                  );
                }
                break;
              case "city_street":
                formData.append(
                  "delivery_address_details__uuid_ref_street",
                  data.value.Ref ? data.value.Ref : "00000000-0000-0000-0000-000000000000"
                );
                formData.append(
                  "delivery_address_details__name_street",
                  this.searchAddressStreetQuery
                );
                break;
              case "city_house":
                formData.append("delivery_address_details__number_house", data.value);
                break;
              case "city_flat":
                formData.append("delivery_address_details__number_flat", data.value);
                break;
              default:
                break;
            }
          });

          if (this.copyRadioServiceType != this.radioServiceType) {
            formData.append("delivery_address_details__service_type", this.radioServiceType);
          }

          if (this.copy_uuid_user_assigned_to != this.uuid_user_assigned_to) {
            formData.append("uuid_user_assigned_to", this.uuid_user_assigned_to);
          }

          let response;
          if (this.settingDialog === "supply") {
            //
            if (mode === "send") {
              formData.append("id_state", 401);
            }
            if (
              this.item.id_state !== this.inputCrmItem.id_state &&
              Number(this.inputCrmItem.id_state) !== 400
            ) {
              formData.append("id_state", this.inputCrmItem.id_state);
            }
            response = await supplyService.updateSupplyItem(formData);

            //
            if (response.data.status === "success") {
              if (mode === "send") {
                this.onClose();
                const responseObject = response.data.object;
                this.changeUserDataHandler({
                  key: "deliveries_balance",
                  value: responseObject?.user_details?.deliveries_balance
                });
                this.changeUserDataHandler({
                  key: "deliveries_negative_limit",
                  value: responseObject?.user_details?.deliveries_negative_limit
                });
              } else {
                this.dataInsertion(response.data?.object);
              }
            }
            EventBus.$emit("supply-out-modified",response.data?.object, true);
          } else if (this.settingDialog === "crm") {
            response = await crmService.updateCrmOrder(formData);
            this.changeUserDataHandler({
              key: "deliveries_balance",
              value: response?.user_details?.deliveries_balance
            });
            this.changeUserDataHandler({
              key: "deliveries_negative_limit",
              value: response?.user_details?.deliveries_negative_limit
            });
            this.dataInsertion(response);
            EventBus.$emit("crm-items-reset");
          }
          this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
          this.loading = false;
          setTimeout(
            function() {
              this.clicked = false;
            }.bind(this),
            1000
          );
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      }
    },
    async onTTN() {
      try {
        this.loading = true;
        const formData = new FormData();
        //
        formData.append("uuid", this.inputCrmItem.uuid);

        formData.append("ttn", this.inputCrmItem.ttn);
        formData.append("cost", this.inputCrmItem.cost);
        formData.append("cash_on_delivery", this.inputCrmItem.cash_on_delivery);

        let response;
        if (this.settingDialog === "supply") {
          response = await supplyService.updateSupplyItem(formData);
          EventBus.$emit("supply-out-modified",this.inputCrmItem);
        } else if (this.settingDialog === "crm") {
          response = await crmService.updateCrmOrder(formData);
          EventBus.$emit("crm-items-reset");
        }

        this.dataInsertion(response.data?.object);

        this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
        this.loading = false;
        this.$v.$reset();
      } catch (e) {
        this.loading = false;
        console.warn(e);
        this.setErrorNotification(e?.error?.description);
      }
    },
    async onSend() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;

          let response;
          if (this.settingDialog === "supply") {
            this.onUpdate("send");
          } else if (this.settingDialog === "crm") {
            response = await crmService.startCrmOrderDelivery(this.inputCrmItem.uuid);
            // orderDialog('response', response);
            if (response.status === "success") {
              this.inputCrmItem.id_state = 401;
              const responseObject = response?.object;
              this.changeUserDataHandler({
                key: "deliveries_balance",
                value: responseObject?.deliveries_balance
              });
              this.changeUserDataHandler({
                key: "deliveries_negative_limit",
                value: responseObject?.deliveries_negative_limit
              });
              this.onClose();
            }

            this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
            this.loading = false;
            EventBus.$emit("crm-items-reset");
            this.$v.$reset();
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      }
    },
    async nextPageChosenProducts() {
      this.chosenProductsPagination.page += 1;
      await this.getChosenProducts(this.item.uuid, false, true);
    },
    async getChosenProducts(uuid, onMount = false, next = false) {
      try {
        this.loadingItems = true;
        this.loadingChosenProducts = true;
        // // orderDialog('this.item.uuid', this.item.uuid);
        if (this.settingDialog === "crm") {
          const res = await crmService.getCrmItemDetailsUuid(uuid);
          this.chosenProducts = res?.object;
          this.totalChosenProducts = res.message;

          if (onMount) {
            if (
              this.getTotalPrice &&
              this.inputCrmItem.cost &&
              this.inputCrmItem.cost != this.getTotalPrice &&
              this.inputCrmItem.cost != "0.00"
            ) {
              this.isProductPriceCost = false;
              this.isProductPriceCostCopy = false;
            }
            if (
              this.getTotalPrice &&
              this.inputCrmItem.cash_on_delivery &&
              this.inputCrmItem.cash_on_delivery != this.getTotalPrice &&
              this.inputCrmItem.cash_on_delivery != "0.00"
            ) {
              this.isProductPriceCashOnDelivery = false;
              this.isProductPriceCashOnDeliveryCopy = false;
            }
          }
        } else if (this.settingDialog === "supply") {
          const params = {
            uuid_delivery: uuid
          };
          const newItems = await supplyService.getDeliveryItemDetailsByDeliveryUuid(params);
          this.chosenProducts = newItems;
        }

        if (!this.chosenProducts) {
          this.chosenProducts = [];
        }

        this.loadingItems = false;
        this.loadingChosenProducts = false;
      } catch (e) {
        this.loadingItems = false;
        this.loadingChosenProducts = false;
        console.error("error", e);
      }
    },
    async getCompletedProducts(uuid) {
      try {
        this.loadingItems = true;
        this.completedItems = await supplyService.getCompletedItemDetailsByDeliveryUuid(uuid, {
          details: true
        }); // remove details
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
      }
    },
    async getItemsInCell() {
      try {
        this.loadingItems = true;
        this.itemsInCell = await depotService.getMyProducts({
          uuid_cell: this.item.uuid_cell
        });
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
      }
    },
    dataReset() {
      this.activeProduct = false;
      this.tabStep = 0;
      this.productTab = "edit";
      this.uuid_user_state = "";
      this.searchString = "";
      this.ttnCodeSvg = "";
      this.ttnCodeStatus = false;
      this.chosenProducts = [];
      this.inputCrmItem = {
        description: "",
        ttn: "",
        cost: "",
        is_blocked: false,
        uuid: "",
        cash_on_delivery: "",
        id_state: 400
      };
      this.inputSender = {
        name: "",
        surname: "",
        middlename: "",
        phone: ""
      };
      this.sender = {
        phoneMenu: false
      };
      this.inputDelivery = {
        name: "",
        surname: "",
        middlename: "",
        phone: "",
        city: "",
        name_city: "",
        warehouse: {},
        name_warehouse: "",
        warehouse_id: ""
      };
      this.delivery = {
        phoneMenu: false,
        items: []
      };
      this.changed = false;
      this.changedStatus = false;
      this.$v.$reset();
    },
    async getMetrics() {
      try {
        if (this.settingDialog === "supply") {
          const metricsItem = await supplyService.getSupplyMetrics(this.item.uuid);
          this.metricsValue = metricsItem;
          //
        }
      } catch (e) {
        // this.setErrorNotification(11);
      }
    },
    offCodeSvg() {
      this.ttnCodeSvg = "";
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD,
      uuid_tab: getterTypes.GET_UUID_TAB
    }),
    isCRM() {
      return this.settingDialog === "crm";
    },
    ...mapGetters(["currentLanguage"]),
    getTableHeight() {
      return this.getCardHeight + 20 + "px";
    },
    getCardHeight() {
      return this.windowHeight - 305 + "px";
    },
    getTotalPrice() {
      if (this.chosenProducts.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[1];
        const number = str.split(":")[1];

        return Number(number)
          .toFixed(2)
          .toString();
      } else {
        return null;
      }
    },
    blockedItem() {
      if (
        (this.permissions.client || this.permissions.employees) &&
        this.inputCrmItem.is_blocked &&
        this.inputCrmItem.type === "out"
      ) {
        return true;
      }
      return false;
    },
    isLoadingCard() {
      if (this.settingDialog === "crm") {
        return this.loadingChosenProducts || this.loading;
      } else {
        return this.loadingChosenProducts || this.loading;
      }
    },
    isSendedCrmOrder() {
      if (this.settingDialog === "crm" && this.typeDialog === "edit") {
        return this.item.type === "out";
      } else {
        return false;
      }
    },
    ttnCode() {
      // orderDialog('this.inputCrmItem.ttn', this.inputCrmItem.ttn);
      return !(this.inputCrmItem.ttn === "" || this.inputCrmItem.ttn == 0);
    },
    sendButtonDisabled() {
      // // orderDialog('this.inputCrmItem.id_state', this.inputCrmItem.id_state);
      if (this.productTab === "edit") {
        if (this.settingDialog === "supply") {
          return (
            (!this.inputDelivery.warehouse && !this.inputAddress.city_street.Ref) ||
            !this.chosenProducts.length
          );
        } else {
          return !this.chosenProducts.length || Number(this.inputCrmItem.id_state) >= "401";
        }
      }
      return true;
    },
    isEnoughProducts() {
      if (this.chosenProducts.length > 0) {
        return this.chosenProducts.every(item => {
          return item.total_price > 0;
        });
      }
      return false;
    },
    visibility: {
      get() {
        if (this.settingDialog === "crm") {
          this.fetchData();
        }
        return this.visible;
      },
      set() {
        this.$emit("close");
        if (this.typeDialog === "add") {
          this.dataReset();
        }
        // this.$destroy();
      }
    },
    deliveryNameErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.name.$dirty) {
        return errors;
      }
      if (!this.$v.inputDelivery.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.name.required && errors.push(this.$t("form.errors.NameRequired"));
      this.tabStep = 0;
      return errors;
    },
    deliveryPaymentMadeErrors() {
      const errors = [];
      if (!this.$v.inputCrmItem.cash_on_delivery.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputCrmItem.cash_on_delivery.required &&
        errors.push(this.$t("form.errors.CashOnDeliveryRequired"));
      this.tabStep = 0;
      return errors;
    },
    deliverySurnameErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.surname.$dirty) {
        return errors;
      }
      if (!this.$v.inputDelivery.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.surname.required &&
        errors.push(this.$t("form.errors.SurnameRequired"));
      this.tabStep = 0;
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.inputDelivery.surname.validText
      // && errors.push('Некоректо введено Пріщвише');
      return errors;
    },
    deliveryMiddlenameErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.middlename.$dirty) {
        return errors;
      }
      if (!this.$v.inputDelivery.middlename.noEngTextValidator) {
        errors.push(this.$t("form.hint.MiddlenameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.middlename.required &&
        errors.push(this.$t("form.errors.MiddlenameRequired"));
      this.tabStep = 0;
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.inputDelivery.surname.validText
      // && errors.push('Некоректо введено Пріщвише');
      return errors;
    },
    senderNameErrors() {
      const errors = [];
      if (!this.$v.inputSender.name.$dirty) {
        return errors;
      }
      if (!this.$v.inputSender.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
        this.tabStep = 0;
      }
      return errors;
    },
    senderSurnameErrors() {
      const errors = [];
      if (!this.$v.inputSender.surname.$dirty) {
        return errors;
      }
      if (!this.$v.inputSender.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
        this.tabStep = 0;
      }
      return errors;
    },
    senderMiddlenameErrors() {
      const errors = [];
      if (!this.$v.inputSender.middlename.$dirty) {
        return errors;
      }
      if (!this.$v.inputSender.middlename.noEngTextValidator) {
        errors.push(this.$t("form.hint.MiddlenameNoEngText"));
        this.tabStep = 0;
      }
      return errors;
    },
    deliveryPhoneErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.phone.required &&
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      this.tabStep = 0;
      return errors;
    },
    senderPhoneErrors() {
      const errors = [];
      if (!this.$v.inputSender.phone.$dirty) {
        return errors;
      }
      !this.$v.inputSender.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      this.tabStep = 0;
      return errors;
    },
    deliveryCityErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.city.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.city.required && errors.push(this.$t("form.errors.CityRequired"));
      this.tabStep = 0;
      return errors;
    },
    deliveryWarehouseErrors() {
      const errors = [];
      if (!this.$v.inputDelivery.warehouse.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDelivery.warehouse.required &&
        errors.push(this.$t("form.errors.DepartmentRequired"));
      this.tabStep = 0;
      return errors;
    },
    deliveryAddressCityErrors() {
      const errors = [];
      if (!this.$v.inputAddress.city_address.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputAddress.city_address.required &&
        errors.push(this.$t("form.errors.CityRequired"));
      this.tabStep = 0;
      return errors;
    },
    deliveryAddressStreetErrors() {
      const errors = [];
      if (!this.$v.inputAddress.city_street.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputAddress.city_street.required &&
        errors.push(this.$t("form.errors.StreetRequired"));
      this.tabStep = 0;
      return errors;
    },
    deliveryAddressHouseErrors() {
      const errors = [];
      if (!this.$v.inputAddress.city_house.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputAddress.city_house.required &&
        errors.push(this.$t("form.errors.HouseRequired"));
      this.tabStep = 0;
      return errors;
    }
    // ttnErrors() {
    // const errors = [];
    // if (!this.$v.inputCrmItem.ttn.$dirty) {
    //  return errors;
    // }
    // eslint-disable-next-line no-unused-expressions
    //  !this.$v.inputCrmItem.ttn.required && errors.push(this.language.isRequired('ТТН'));
    //  return errors;
    //  }
  },
  validations() {
    return {
      inputDelivery: {
        name: {
          required: requiredIf(function() {
            return !this.disableRequiredFields;
          }),
          noEngTextValidator: noEngTextValidator
        },
        surname: {
          noEngTextValidator: noEngTextValidator,
          required: requiredIf(() => {
            const isWarehous = this.radioServiceType == "DoorsWarehouse";
            const isDisableRequiredFields = !this.disableRequiredFields;
            return isWarehous || isDisableRequiredFields;
          })
        },
        middlename: {
          noEngTextValidator: noEngTextValidator,
          required: requiredIf(() => {
            return this.radioServiceType == "DoorsWarehouse";
          })
        },
        phone: {
          required: requiredIf(function() {
            return !this.disableRequiredFields;
          }),
          phoneMask
        },
        city: {
          required: requiredIf(() => {
            return this.radioServiceType == "WarehouseWarehouse";
          })
        },
        warehouse: {
          required: requiredIf(() => {
            return this.radioServiceType == "WarehouseWarehouse";
          })
        }
      },
      inputAddress: {
        city_address: {
          required: requiredIf(() => {
            return this.radioServiceType == "DoorsWarehouse";
          })
        },
        city_street: {
          required: requiredIf(() => {
            return this.radioServiceType == "DoorsWarehouse";
          })
        },
        city_house: {
          required: requiredIf(() => {
            return this.radioServiceType == "DoorsWarehouse";
          })
        }
      },
      inputCrmItem: {
        cash_on_delivery: {
          required: requiredIf(() => {
            return this.inputCrmItem.pay_type == "CASH_ON_DELIVERY" && this.statusCreateTTN;
          })
        }
        // ttn: {
        //  required: requiredIf(() => {
        // return this.inputCrmItem.id_state == 412;
        //  })
        // }
      },
      inputSender: {
        phone: { phoneMask },
        name: {
          noEngTextValidator: noEngTextValidator
        },
        surname: {
          noEngTextValidator: noEngTextValidator
        },
        middlename: {
          noEngTextValidator: noEngTextValidator
        }
      }
    };
  },
  props: {
    isCallCenter: {
      required: false,
      default: false
    },
    isAllowEditState: {
      required: false
    },
    isAllowSendOrder: {
      type: Boolean,
      default: true
    },
    "no-cross": {
      type: Boolean,
      default: false
    },
    isAccessDeportInfo: {
      type: Boolean,
      default: true
    },
    user_states: {
      type: Array
    },
    isAllowAssignedUserChange: {
      required: false
    },
    typeDialog: String,
    uuidItem: String,
    settingDialog: String,
    visible: {
      required: false,
      type: Boolean
    },
    onlyContent: Boolean
  },
  components: {
    productListComponent,
    ConfirmDeleteDialog,
    ConfirmLeftDialog,
    headerDialogSupply,
    headerDialogCrm,
    infoDialogCrm,
    imgDialog,
    DeliveryChatComponent,
    NsSmsIntegration,
    ReturnSuppply,
    ConfirmDialogSlots,
    itemsViewTreeView,
    itemsView,
    StatusCircle,
    EditSupplyInDialog,
    CallCenterDismissDialogVue,
    GenerateTTNDialog,
    cashierDialog,
    chooseCashierDialog
  },
  mixins: [
    validationMixin,
    notifications,
    novaPochta,
    user,
    language,
    OrderMixin,
    imagesCheck,
    availableItemsCheck,
    localStorageMixin
  ],
  watch: {
    searchWarehouseQuery(e) {
      if (!this.isTtn) {
        this.onSearchWarehouse(e);
      }
    },
    isProductPriceCashOnDelivery: {
      handler(e) {
        this.checkChanged("cashOnDelivery");
        if (
          e &&
          (this.inputCrmItem.pay_type == "CASH_ON_DELIVERY" ||
            this.inputCrmItem.pay_type == "PAYMENT_CONTROL")
        ) {
          this.inputCrmItem.cash_on_delivery = this.getTotalPrice;
        } else {
          // this.inputCrmItem.cash_on_delivery = null
        }
      }
    },
    totalChosenProducts: {
      handler() {
        if (
          this.isProductPriceCashOnDelivery &&
          (this.inputCrmItem.pay_type == "CASH_ON_DELIVERY" ||
            this.inputCrmItem.pay_type == "PAYMENT_CONTROL")
        ) {
          this.inputCrmItem.cash_on_delivery = this.getTotalPrice;
        }
        if (this.isProductPriceCost) {
          this.inputCrmItem.cost = this.getTotalPrice;
          this.copyCrmItem = {
            ...this.inputCrmItem
          };
        }
      }
    },
    isProductPriceCost: {
      handler(e) {
        this.checkChanged("priceCost");

        if (e) {
          this.inputCrmItem.cost = this.getTotalPrice;
        } else {
          // this.inputCrmItem.cost = null
        }
      }
    },
    searchAddressCityQuery(val) {
      if (!this.ttnCodeStatus) {
        this.pagination.page = 1;
        this.getSettlements(false);
      }
    },
    searchAddressStreetQuery(val) {
      if (!this.ttnCodeStatus) {
        this.paginationStreets.page = 1;
        this.getStreets(false, false);
      }
    },
    searchCityQuery: {
      handler(e) {
        if (e === null || e.length < 3) {
          this.inputDelivery.warehouse = {};
          this.inputDelivery.city = "";
          return;
        }
        if (this.isInsertData) {
          this.isInsertData = !this.isInsertData;
          return;
        }
        this.cityPagination.page = 1;
        this.cityPagination.searchQuery = e;
        this.isLoadingCity = true;
        this.onFocusCities(false);
        if (e && this.$refs.deliveryCity.filteredItems.length != this.activeCities.length) {
          this.$refs.deliveryCity.filteredItems.sort(function(a, b) {
            return (
              b.Description.toLowerCase().startsWith(e.toLowerCase()) -
              a.Description.toLowerCase().startsWith(e.toLowerCase())
            );
          });
        }
      }
    },
    imagesCheckbox: {
      handler(e) {
        this.handleImageCheckBox(e);
      }
    },
    availableItemsCheckbox: {
      handler(e) {
        this.handleItemsCheckBox(e);
      }
    },
    isCRMProduct: {
      handler() {
        if (this.settingDialog == "crm") {
          this.imagesCheckbox = this.crmImagesCheck;
          this.availableItemsCheckbox = this.crmItemsCheck;
        } else {
          this.imagesCheckbox = this.deliveriesImagesCheck;
          this.availableItemsCheckbox = this.deliveriesItemsCheck;
        }
      }
    },

    tabItemsStep: {
      deep: true,
      handler(e) {
        if (e === 2 && !this.itemsInCell.length) {
          this.getItemsInCell();
        }
      }
    },
    counterparty: {
      deep: true,
      handler(e) {
        if (!this.counterparty) return;

        if (
          this.counterparty.OwnershipFormDescription === "ФОП" &&
          this.counterparty.AfterpaymentOnGoodsCost
        ) {
          this.payTypes.splice(1, 0, {
            value: "PAYMENT_CONTROL"
          });
          this.inputCrmItem.pay_type = "PAYMENT_CONTROL";
        } else {
          this.payTypes = [
            {
              value: null
            },
            {
              value: "CASH_ON_DELIVERY"
            },
            {
              value: "PREPAY"
            },
            {
              value: "PAYED"
            },
            {
              value: "REJECT"
            }
          ];
        }
      }
    },

    inputSender: {
      deep: true,
      handler(e) {
        this.checkChanged("sender");
      }
    },
    inputDelivery: {
      deep: true,
      handler(e) {
        this.checkChanged("delivery");
      }
    },
    inputAddress: {
      deep: true,
      handler(e) {
        this.checkChanged("address");
      }
    },
    inputCrmItem: {
      deep: true,
      handler(e) {
        this.checkChanged("crm_item");
      }
    },
    statusCreateTTN: {
      deep: true,
      handler(e) {
        this.checkChanged("status");
      }
    },
    uuid_user_assigned_to: {
      handler(e) {
        this.checkChanged("assigned");
      }
    }
  }
};
</script>

<style lang="scss">
@import "orderDialog";
</style>

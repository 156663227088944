import * as getterTypes from "./types/getters";
import * as actionTypes from "./types/actions";
// eslint-disable-next-line import/no-cycle
import store from "@/store";

export default {
  [getterTypes.GET_MENU_POSITION]: state => state.menuPosition,
  [getterTypes.GET_SHOW_500_ERROR_DIALOG]: state => state.show500ErrorDialog,
  [getterTypes.GET_USER_DATA]: state => state.user,
  [getterTypes.GET_PERMISSIONS]: state => {
    const isOwner = () => state.user?.type === "owner";
    const isAdmin = () => state.user?.type === "admin";
    const isManager = () => state.user?.type === "manager";
    const isEmployee = () => state.user?.type === "employee";
    const isClient = () => state.user?.type === "client";
    const notClient = () => state.user?.type !== "client";
    const isCrmUser = () => state.user?.type == "crm";
    const all = () =>  isOwner() || isAdmin() || isEmployee() || isManager() || isClient() || isCrmUser();

    const accessCrm = () => {
      return state.user?.crm_is_active;
    };
    return {
      can_edit_depot: isOwner() || isAdmin(),
      can_create_depot: isOwner(),
      can_create_ns_code: isManager(),
      can_delete_depot: false,
      can_create_sector: isAdmin() || isOwner(),
      can_search: isAdmin() || isOwner(),
      can_suplly_filter: isAdmin() || isOwner(),
      can_dop_data: isAdmin() || isOwner(),
      can_create_user_deport: isAdmin() || isOwner(),
      can_edit_sector: isOwner() || isAdmin(), // isManager()
      can_delete_sector: false,
      can_create_cell: all(),
      can_edit_cell: isAdmin() || isOwner() || isManager(),
      can_create_item: all(),
      can_edit_item: all(),
      can_create_delivery: isAdmin() || isOwner(),
      can_view_users_page: notClient() && !isCrmUser(),
      can_view_cells_page: notClient() && !isEmployee(),
      can_view_depot_page: notClient() && !isEmployee(),
      can_view_sectors_page: notClient() && !isEmployee(),
      can_view_ns_codes_page: isManager(),
      can_view_move_list_page: notClient() && !isCrmUser(),
      can_create_move_list_deport: isOwner() || isAdmin(),
      can_delay_move_list: isOwner() || isAdmin() || isManager(),
      can_create_admin: isAdmin() || isOwner(),
      can_create_manager: isAdmin() || isOwner(),
      can_create_employee: isAdmin() || isOwner() || isManager(),
      can_create_client: isOwner() || isAdmin() || isManager(),
      can_block_admins: isOwner(),
      can_block_managers: isAdmin() || isOwner(),
      can_block_employees: isAdmin() || isOwner() || isManager(),
      can_block_clients: isOwner() || isAdmin() || isManager(),
      can_block_crm: isOwner() || isAdmin() || isManager(),
      can_activate_admins: isOwner(),
      can_activate_managers: isAdmin() || isOwner(),
      can_activate_employees: isAdmin() || isOwner() || isManager(),
      can_activate_clients: isOwner() || isAdmin() || isManager(),
      can_activate_crm: isOwner() || isAdmin() || isManager(),
      can_edit_admin: isAdmin() || isOwner(),
      can_edit_manager: isAdmin() || isOwner(),
      can_edit_employee: isOwner() || isAdmin() || isManager(),
      can_edit_client: isAdmin() || isOwner() || isManager(),
      can_delete_admin: isAdmin() || isOwner(),
      can_delete_manager: isAdmin() || isOwner(),
      can_catalog: (isClient() && accessCrm()) || isAdmin() || isOwner() || isManager(),
      can_delete_employee: isAdmin() || isOwner(),
      can_delete_client: isOwner() || isAdmin() || isManager(),
      can_create_owner: isOwner(),
      can_delete_owner: isOwner(),
      can_edit_owner: isOwner(),
      can_view_crm: all(),
      can_edit_date_end_crm: isOwner() || isAdmin() || isManager(),
      can_use_depots: !isClient() && !isCrmUser(),
      clients_products: isClient(),
      can_notifyAudion: isManager(),
      deliveries_chart: !isClient(),
      deliveries_history_chart: isManager() || isOwner() || isAdmin(),
      client: isClient(),
      can_generate_ttn: isClient() || accessCrm(),
      employees: isEmployee(),
      owner: isOwner(),
      not_client: notClient(),
      can_see_sizy_hours_data: isClient(),
      can_see_sizy_data: isClient() || isManager() || isEmployee(),
      can_see_history: isEmployee() || isManager() || isAdmin() || isOwner(),
      can_see_client_reports: isManager(),
      can_add_income_by_deport: isAdmin() || isOwner(),
      can_see_month_chart: isManager() || isClient(),
      can_clear_move_list: isManager(),
      can_view_plans_list_page: isOwner() || isEmployee() || isAdmin() || isManager() || isClient(),
      can_view_plans_list: isOwner() || isManager(),
      can_only_see_plans: !isOwner(),
      can_choose_deport: isOwner() || isAdmin(),
      can_return_delivery: isOwner() || isAdmin() || isManager(),
      crm_user: isCrmUser(),
      call_center: (isCrmUser() || isClient()) && accessCrm(),
      access_crm: accessCrm(),
      more_than_client: isOwner() || isAdmin() || isManager(),
      can_see_crm_users_list: isOwner() || isAdmin() || isManager(),
      can_see_managers_and_higher_list: isOwner() || isAdmin() || isManager(),
      can_see_sms_integrations: isClient(),
      can_edit_documents: isOwner() || isAdmin() || isManager(),
      can_add_documents: isOwner() || isAdmin() || isManager(),
      can_view_document_author: isOwner() || isAdmin() || isManager(),
      can_view_join_request: isOwner() || isAdmin() || isManager(),
      can_delete_join_requests: isOwner() || isAdmin(),
      can_view_default_plan: isOwner() || isAdmin() || isManager(),
      can_see_user_menu: all(),
      can_see_password_menu: all(),
      can_see_theme_menu: all(),
      can_see_api_keys_menu: isClient() || isManager(),
      can_do_import: isOwner() || isEmployee() || isAdmin() || isClient(),
      can_edit_board: isOwner() || isEmployee() || isAdmin() || isClient(),
      can_see_leads: all() && !isCrmUser(),
      access_balance: isCrmUser() || isClient() || isManager(),
      can_see_deport_speedometer:  isManager(),
      can_see_states_map: isOwner() || isAdmin() || isManager(),
      can_choose_assigned_user: isCrmUser(),
      can_view_transactions_page: all(),
      can_see_balance: isManager() || isClient(),
      balance_client: isClient(),
      balance_manager: isManager(),
      manager: isManager(),
      pages: {
        cabinet: !isCrmUser(),
        crm: (isCrmUser() || isClient()) && accessCrm(),
        addresses: all(),
        prices: (isCrmUser() || isClient()) && accessCrm(),
        "move-list": notClient() && !isCrmUser(),
        "plans-list": isOwner() || isEmployee() || isAdmin() || isManager() || isClient(),
        supply: !isCrmUser(),
        "supply-details": !isCrmUser(),
        depots: isOwner() || isAdmin() || isManager(),
        cells: isOwner() || isAdmin() || isManager(),
        "ns-codes": isOwner() || isAdmin() || isManager(),
        sectors: isOwner() || isAdmin() || isManager(),
        products: !isCrmUser(),
        "nested-data": isOwner() || isManager(),
        "products-bar-code": !isCrmUser(),
        "transactions": all(),
        catalog:
          ((isCrmUser() || isClient()) && accessCrm()) || isAdmin() || isOwner() || isManager(),
        clients: notClient() && !isCrmUser(),
        managers: notClient() && !isCrmUser(),
        employees: notClient() && !isCrmUser(),
        admins: notClient() && !isCrmUser(),
        owners: notClient() && !isCrmUser(),
        market: all(),
        profile: all(),
        documents: all(),
        "call-center": (isClient() || isCrmUser()) && accessCrm(),
        "crm-users": isOwner() || isAdmin() || isManager(),

        landing: all(),
        monitor: all(),
        "landing-documents": all(),
        "contacts": isOwner() || isManager(),
        "barcode-details": all(),
        "ns-codes_ranges": isOwner() || isAdmin() || isManager(),
        "tool": isAdmin() || isOwner(),
        "payment-success": all()
      }
    };
  }
};

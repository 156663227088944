// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';
import store from "@/store";
import * as actionTypes from "@/store/modules/totalValues/types/actions";

const prefix = '/deliveries';

export default {
  async getSupplyList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, {
      ...params,
      order: 'DESC',
      orderby: 'time_created',
      show_total: true
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data;
  },
  async getSupplyListOut(params) {
    const response = await requestService.get(`${prefix}/list`, {
      ...params,
      type: 'out',
      order: 'DESC',
      orderby: 'time_created',
      show_total: true
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    const message = response?.data?.message;
    const totalMatch = message.match(/total:\s*(\d+)/);
    let total;
    if (totalMatch) {
      total = totalMatch[1];
    } else {
      total = 0
    }
    store.dispatch(`totalValues/${actionTypes.SET_TOTAL_OUT}`,  total);
    return response?.data?.object;
  },
  async getSupplyListIn(params) {
    const response = await requestService.get(`${prefix}/list`, {
      ...params,
      type: 'in',
      show_returns: 0,
      order: 'DESC',
      orderby: 'time_created',
      show_total: true
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    const message = response?.data?.message;
    const totalMatch = message.match(/total:\s*(\d+)/);
    let total;
    if (totalMatch) {
      total = totalMatch[1];
    } else {
      total = 0
    }
    store.dispatch(`totalValues/${actionTypes.SET_TOTAL_IN}`,  total);
    return response?.data?.object;
  },
  async getSupplyListReturned(params) {
    const response = await requestService.get(`${prefix}/list`, {
      ...params,
      type: 'in',
      show_returns: 1,
      order: 'DESC',
      orderby: 'time_created',
      show_total: true
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    const message = response?.data?.message;
    const totalMatch = message.match(/total:\s*(\d+)/);
    let total;
    if (totalMatch) {
      total = totalMatch[1];
    } else {
      total = 0
    }
    store.dispatch(`totalValues/${actionTypes.SET_TOTAL_RETURN}`,  total);
    return response?.data?.object;
  },
  async getSupplyItem(params ) {
    const response = await requestService.get(`${prefix}/info/${params}`);
    return response?.data?.object;
  },
  async getSupplyMetrics(params ) {
    const response = await requestService.get(`${prefix}/metrics/${params}`,{},{},false);
    return response?.data?.object;
  },
  async addSupplyItem(payload) {
    const response = await requestService.post(`${prefix}/add`, payload,{},false);
    return response?.data?.object;
  },
  async returnSupply(payload) {
    const response = await requestService.post(`${prefix}/return/add`, payload,{},false);
    return response?.data?.object;
  },
  async returnItemList(payload) {
    const response = await requestService.get(`${prefix}/return/list?uuid=${payload.uuid}&details=${payload.details}`);
    return response?.data?.object;
  },

  async blockSupplyItem(payload) {
    const response = await requestService.patch(`${prefix}/block/${payload}`);
    return response?.data?.object;
  },
  async unblockSupplyItem(payload) {
    const response = await requestService.patch(`${prefix}/unblock/${payload}`);
    return response?.data?.object;
  },
  async deleteSupplyItem(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async updateSupplyItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/update`, payload,{},false);
  },
  async addDeliveryItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/items/add`, payload);
  },
  async addDeliveryItemBarCode(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/items/add_bar_code`, payload);
  },
  async addDeliveryItemAddProduct(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/items/add_product`, payload);
  },
  async ChangeItemValue(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/items/amount`,payload);
    return response?.data?.object;

  },
  async startDelivery(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/delivery/start/${payload}`);
  },
  async getNextStatusId(statusId) {
    const response = await requestService.get('/deliveries/states/next', {
      id_state: statusId,
    });
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getStatesList(params){
    const response = await requestService.get(`/states/map`, params);
    return response?.data;
  },
  async getDeliveryItemDetailsByDeliveryUuid(params) {
    const response = await requestService.get(`${prefix}/items/list/details`, params);
    //
    return response?.data?.object;
  },

  async getCompletedItemDetailsByDeliveryUuid(uuid,params) {
    const response = await requestService.get(`${prefix}/completed/items/list/${uuid}`,params);
    //
    return response?.data?.object;
  },
  async deleteDeliveryItem(itemUuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/items/delete/${itemUuid}`, {}, false);
  },
  async deleteDeliverySuplly(supllyUuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${supllyUuid}`);
  },
  async chatLog(deliveriesOutUuid) {
    // eslint-disable-next-line no-return-await
    const resposne = await requestService.get(`${prefix}/chat/log/${deliveriesOutUuid}`);
    return resposne?.data?.object;

  },
  async chatHistory(deliveriesOutUuid) {
    // eslint-disable-next-line no-return-await
    const resposne = await requestService.get(`/crm/states/history/${deliveriesOutUuid}`);
    return resposne?.data?.object;

  },
  async chatMessage(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/chat/message`,payload);
  },
  async generateTTN(uuid, payload) {
    const resposne = await requestService.post(`${prefix}/idoc/generate/${uuid}`, payload, {}, false);
    return resposne?.data?.object;
  },
  async infoTTN(tnn) {
    const resposne = await requestService.get(`${prefix}/idoc/info/${tnn}`);
    return resposne?.data?.object;
  },
  async loadTTN(tnn,payload) {
    const resposne = await requestService.post(`${prefix}/idoc/load/${tnn}`,payload);
    return resposne?.data?.object;
  },
  async deliveriesStateHistory(uuid){
    const response = await requestService.get(`${prefix}/state_history/${uuid}`);
    return response?.data?.object;
  },
  async getTransactions(payload){
    const extendedPayload = {
      ...payload,
      orderby: 'time_created',
      order: 'DESC'
    };

    // Виконуємо запит з розширеним payload
    const response = await requestService.get(`/transactions/list`, extendedPayload);

    return response?.data?.object;
  }
};
